import {uniq} from 'lodash'
import {Reducer} from 'react'

import {
  DYNAMIC_SELECT_DATA_SCOPE_TYPE,
  LOOKUP_DATA_SCOPE_TYPE,
  getDataScopeConfiguration
} from './DataScopeConfiguration'
import {CustomersScopes, GroupedRoleAssignment, RoleType} from './ManageUsers.selectors'

const emptyGroupedRoleAssignment: GroupedRoleAssignment = {
  groupId: '',
  sourceRolesIds: [],
  roleType: '',
  userId: '',
  commonDataScopes: {},
  variantDataScopes: {
    allCustomersRoleId: null,
    customersScopes: []
  },
  unsupportedDataScopes: {}
}

export const CHANGE_ROLE_TYPE = 'CHANGE_ROLE_TYPE'
export const CHANGE_COMMON_DATASCOPE_VALUE = 'CHANGE_COMMON_DATASCOPE_VALUE'
export const ENABLE_ALL_CUSTOMERS = 'ENABLE_ALL_CUSTOMERS'
export const DISABLE_ALL_CUSTOMERS = 'DISABLE_ALL_CUSTOMERS'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const ADD_PAYER = 'ADD_PAYER'
export const ADD_PLANT = 'ADD_PLANT'
export const ADD_COMMENT_CATEGORY = 'ADD_COMMENT_CATEGORY'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const DELETE_PAYER = 'DELETE_PAYER'
export const DELETE_PLANT = 'DELETE_PLANT'
export const DELETE_COMMENT_CATEGORY = 'DELETE_COMMENT_CATEGORY'
export const ADD_CUSTOMER_SCOPE = 'ADD_CUSTOMER_SCOPE'
export const REMOVE_CUSTOMER_SCOPE = 'REMOVE_CUSTOMER_SCOPE'
export const REPLACE_CUSTOMERS_AND_PROJECTS_SITES = 'REPLACE_CUSTOMERS_AND_PROJECTS_SITES'
export const DELETE_UNSUPPORTED_SCOPE = 'DELETE_UNSUPPORTED_SCOPE'
export const ADD_HAULIER = 'ADD_HAULIER'
export const DELETE_HAULIER = 'DELETE_HAULIER'

const addCustomer = (customersScopes: CustomersScopes[], customerId: string): CustomersScopes[] => {
  const customerExists =
    customersScopes.findIndex((customerScopes) => customerScopes.customerId === customerId) > -1

  if (customerExists) {
    return customersScopes
  }

  return [
    ...customersScopes,
    {customerId, roleId: -1, siteIds: [], projectIds: [], contractIds: []}
  ]
}

const addPayer = (payerIds: string[] | undefined, payerId: string): string[] => {
  if (payerIds === undefined) {
    return [payerId]
  }

  if (payerIds.includes(payerId)) {
    return payerIds
  }

  return [...payerIds, payerId]
}

const addHaulier = (haulierIds: string[] | undefined, haulierId: string): string[] => {
  if (haulierIds === undefined) {
    return [haulierId]
  }

  if (haulierIds.includes(haulierId)) {
    return haulierIds
  }

  return [...haulierIds, haulierId]
}

const deleteCustomer = (
  customersScopes: CustomersScopes[],
  customerId: string
): CustomersScopes[] => {
  const customerIndex = customersScopes.findIndex(
    (customerScopes) => customerScopes.customerId === customerId
  )

  if (customerIndex === -1) {
    return customersScopes
  }

  return [...customersScopes.slice(0, customerIndex), ...customersScopes.slice(customerIndex + 1)]
}

interface AddCustomerScopeArgs {
  customersScopes: CustomersScopes[]
  customerId: string
  siteId: string
  projectId: string
  contractId: string
}

export const addCustomerScope = ({
  customersScopes,
  customerId,
  siteId,
  projectId,
  contractId
}: AddCustomerScopeArgs): CustomersScopes[] => {
  const customerIndex = customersScopes.findIndex(
    (customerScopes) => customerScopes.customerId === customerId
  )

  if (customerIndex === -1) {
    if (siteId) {
      return [
        ...customersScopes,
        {customerId, roleId: -1, siteIds: [siteId], projectIds: [], contractIds: []}
      ]
    }
    if (contractId) {
      return [
        ...customersScopes,
        {customerId, roleId: -1, siteIds: [], projectIds: [], contractIds: [contractId]}
      ]
    }
    if (projectId) {
      return [
        ...customersScopes,
        {customerId, roleId: -1, siteIds: [], projectIds: [projectId], contractIds: []}
      ]
    }

    return [...customersScopes]
  }

  const siteIds = customersScopes[customerIndex].siteIds
  const siteIndex = siteIds.indexOf(siteId)

  const projectIds = customersScopes[customerIndex].projectIds
  const projectIndex = projectIds.indexOf(projectId)

  const contractIds = customersScopes[customerIndex].contractIds
  const contractIndex = contractIds.indexOf(contractId)

  if (
    (siteId && siteIndex > -1) ||
    (projectId && projectIndex > -1) ||
    (contractId && contractIndex > -1)
  ) {
    return customersScopes
  }

  let customerScopes: CustomersScopes
  if (siteId) {
    customerScopes = {
      ...customersScopes[customerIndex],
      siteIds: [...siteIds, siteId]
    }
  } else if (contractId) {
    customerScopes = {
      ...customersScopes[customerIndex],
      contractIds: [...contractIds, contractId]
    }
  } else if (projectId) {
    customerScopes = {
      ...customersScopes[customerIndex],
      projectIds: [...projectIds, projectId]
    }
  } else {
    customerScopes = {
      ...customersScopes[customerIndex]
    }
  }

  return [
    ...customersScopes.slice(0, customerIndex),
    customerScopes,
    ...customersScopes.slice(customerIndex + 1)
  ]
}

export const removeCustomerScope = ({
  customersScopes,
  customerId,
  siteId,
  projectId,
  contractId
}: AddCustomerScopeArgs): CustomersScopes[] => {
  const customerIndex = customersScopes.findIndex(
    (customerScopes) => customerScopes.customerId === customerId
  )

  if (customerIndex === -1) {
    if (siteId) {
      return [
        ...customersScopes,
        {customerId, roleId: -1, siteIds: [siteId], projectIds: [], contractIds: []}
      ]
    }

    if (contractId) {
      return [
        ...customersScopes,
        {customerId, roleId: -1, siteIds: [], projectIds: [], contractIds: [contractId]}
      ]
    }
    if (projectId) {
      return [
        ...customersScopes,
        {customerId, roleId: -1, siteIds: [], projectIds: [projectId], contractIds: []}
      ]
    }

    return [...customersScopes]
  }

  const siteIds = customersScopes[customerIndex].siteIds
  const siteIndex = siteIds.indexOf(siteId)

  const projectIds = customersScopes[customerIndex].projectIds
  const projectIndex = projectIds.indexOf(projectId)

  const contractIds = customersScopes[customerIndex].contractIds
  const contractIndex = contractIds.indexOf(projectId)

  if (
    (siteId && siteIndex === -1) ||
    (projectId && projectIndex === -1) ||
    (contractId && contractIndex > -1)
  ) {
    return customersScopes
  }

  return customersScopes.map((singleCustomerScopes, i) => {
    if (i !== customerIndex) return singleCustomerScopes

    if (siteId) {
      return {
        ...singleCustomerScopes,
        siteIds: siteIds.filter((_, i) => siteIndex !== i)
      }
    }

    if (contractId) {
      return {
        ...singleCustomerScopes,
        contractIds: contractIds.filter((_, i) => contractIndex !== i)
      }
    }

    if (projectId) {
      return {
        ...singleCustomerScopes,
        projectIds: projectIds.filter((_, i) => projectIndex !== i)
      }
    }

    return singleCustomerScopes
  })
}

const changeCommonDataScopeValue = (
  state: GroupedRoleAssignment,
  dataScopeName: string,
  dataScopeValue: any
) => {
  const originalCommonDataScopes = state.commonDataScopes

  // Reset invalidated commonDataScopes
  const commonDataScopes = Object.keys(originalCommonDataScopes).reduce(
    (commonDataScopesAcc, commonDataScopeName) => {
      // TODO: this should be a pure function!
      const dataScopeConfiguration = getDataScopeConfiguration(commonDataScopeName)

      if (
        dataScopeConfiguration.type !== DYNAMIC_SELECT_DATA_SCOPE_TYPE &&
        dataScopeConfiguration.type !== LOOKUP_DATA_SCOPE_TYPE
      ) {
        return {
          ...commonDataScopesAcc,
          [commonDataScopeName]: originalCommonDataScopes[commonDataScopeName]
        }
      }

      if (!dataScopeConfiguration.dependsOn.includes(dataScopeName)) {
        return {
          ...commonDataScopesAcc,
          [commonDataScopeName]: originalCommonDataScopes[commonDataScopeName]
        }
      }

      return commonDataScopesAcc
    },
    {}
  )

  return {
    ...state,
    commonDataScopes: {
      ...commonDataScopes,
      [dataScopeName]: dataScopeValue
    },
    variantDataScopes: {
      allCustomersRoleId: null,
      customersScopes: []
    }
  }
}

export type GroupedRoleReducerAction =
  | {type: 'CHANGE_ROLE_TYPE'; payload: RoleType}
  | {type: 'CHANGE_COMMON_DATASCOPE_VALUE'; payload: {name: string; value: string | string[]}}
  | {type: 'ENABLE_ALL_CUSTOMERS'}
  | {type: 'DISABLE_ALL_CUSTOMERS'}
  | {type: 'ADD_CUSTOMER'; payload: string}
  | {type: 'ADD_PAYER'; payload: string}
  | {type: 'ADD_HAULIER'; payload: string}
  | {type: 'ADD_PLANT'; payload: string}
  | {type: 'ADD_COMMENT_CATEGORY'; payload: string}
  | {type: 'ADD_CONTRACT'; payload: string}
  | {
      type: 'ADD_CUSTOMER_SCOPE'
      payload: {customerId?: string; siteId?: string; projectId?: string; contractId?: string}
    }
  | {
      type: 'REMOVE_CUSTOMER_SCOPE'
      payload: {customerId?: string; siteId?: string; projectId?: string; contractId?: string}
    }
  | {type: 'DELETE_CUSTOMER'; payload: string}
  | {type: 'DELETE_COMMENT_CATEGORY'; payload: string}
  | {type: 'DELETE_PAYER'; payload: string}
  | {type: 'DELETE_HAULIER'; payload: string}
  | {type: 'DELETE_PLANT'; payload: string}
  | {type: 'DELETE_CONTRACT'; payload: string}
  | {type: 'REPLACE_CUSTOMERS_AND_PROJECTS_SITES'; payload: CustomersScopes[]}
  | {type: 'DELETE_UNSUPPORTED_SCOPE'; payload: {dataScopeName: string; dataScopeValue: string}}

export const groupedRoleAssignmentReducer: Reducer<
  GroupedRoleAssignment,
  GroupedRoleReducerAction
  // eslint-disable-next-line complexity
> = (state, action) => {
  switch (action.type) {
    case CHANGE_ROLE_TYPE:
      return {
        ...emptyGroupedRoleAssignment,
        userId: state.userId,
        groupId: state.groupId,
        sourceRolesIds: state.sourceRolesIds,
        roleType: action.payload
      }

    case CHANGE_COMMON_DATASCOPE_VALUE:
      return changeCommonDataScopeValue(state, action.payload.name, action.payload.value)

    case ENABLE_ALL_CUSTOMERS:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          allCustomersRoleId: -1
        }
      }

    case DISABLE_ALL_CUSTOMERS:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          allCustomersRoleId: null
        }
      }

    case ADD_CUSTOMER:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          allCustomersRoleId: null,
          customersScopes: addCustomer(state.variantDataScopes.customersScopes, action.payload)
        }
      }

    case ADD_PAYER:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          allCustomersRoleId: null,
          payerIds: addPayer(state.variantDataScopes.payerIds, action.payload)
        }
      }

    case ADD_HAULIER:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          allCustomersRoleId: null,
          haulierIds: addHaulier(state.variantDataScopes.haulierIds, action.payload)
        }
      }

    case ADD_PLANT:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          plantIds: [...(state.variantDataScopes.plantIds || []), action.payload]
        }
      }

    case ADD_COMMENT_CATEGORY:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          commentCategories: uniq([
            ...(state.variantDataScopes.commentCategories || []),
            action.payload
          ])
        }
      }

    case DELETE_COMMENT_CATEGORY:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          commentCategories:
            state.variantDataScopes.commentCategories?.filter(
              (category) => category !== action.payload
            ) ?? []
        }
      }

    case DELETE_CUSTOMER:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          allCustomersRoleId: null,
          customersScopes: deleteCustomer(state.variantDataScopes.customersScopes, action.payload)
        }
      }

    case DELETE_PAYER:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          payerIds: state.variantDataScopes.payerIds?.filter((id) => id !== action.payload)
        }
      }

    case DELETE_HAULIER:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          haulierIds: state.variantDataScopes.haulierIds?.filter((id) => id !== action.payload)
        }
      }

    case DELETE_PLANT:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          plantIds: state.variantDataScopes.plantIds?.filter((id) => id !== action.payload)
        }
      }

    case ADD_CUSTOMER_SCOPE:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          allCustomersRoleId: null,
          customersScopes: addCustomerScope({
            customersScopes: state.variantDataScopes.customersScopes,
            customerId: action.payload.customerId as string,
            siteId: action.payload.siteId as string,
            projectId: action.payload.projectId as string,
            contractId: action.payload.contractId as string
          })
        }
      }

    case REMOVE_CUSTOMER_SCOPE:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          allCustomersRoleId: null,
          customersScopes: removeCustomerScope({
            customersScopes: state.variantDataScopes.customersScopes,
            customerId: action.payload.customerId as string,
            siteId: action.payload.siteId as string,
            projectId: action.payload.projectId as string,
            contractId: action.payload.contractId as string
          })
        }
      }

    case REPLACE_CUSTOMERS_AND_PROJECTS_SITES:
      return {
        ...state,
        variantDataScopes: {
          ...state.variantDataScopes,
          allCustomersRoleId: null,
          customersScopes: action.payload
        }
      }
    case DELETE_UNSUPPORTED_SCOPE: {
      const scopes = state.unsupportedDataScopes[action.payload.dataScopeName] as string[]
      const newScopes = scopes.filter((x) => x !== action.payload.dataScopeValue)
      return {
        ...state,
        unsupportedDataScopes: {
          ...state.unsupportedDataScopes,
          [action.payload.dataScopeName]: newScopes
        }
      }
    }

    default:
      return state
  }
}

export type CustomersAndSitesActions =
  | {
      type: 'ADD_CUSTOMER_SCOPE'
      payload: {customerId: string; siteId?: string; projectId?: string; contractId?: string}
    }
  | {
      type: 'REMOVE_CUSTOMER_SCOPE'
      payload: {customerId: string; siteId?: string; projectId?: string; contractId?: string}
    }

export const customersScopesReducer: Reducer<CustomersScopes[], CustomersAndSitesActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ADD_CUSTOMER_SCOPE:
      return addCustomerScope({
        customersScopes: state,
        customerId: action.payload.customerId,
        siteId: action.payload.siteId as string,
        projectId: action.payload.projectId as string,
        contractId: action.payload.contractId as string
      })

    case REMOVE_CUSTOMER_SCOPE:
      return removeCustomerScope({
        customersScopes: state,
        customerId: action.payload.customerId,
        siteId: action.payload.siteId as string,
        projectId: action.payload.projectId as string,
        contractId: action.payload.contractId as string
      })

    default:
      throw new Error()
  }
}
