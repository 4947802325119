import {ButtonBase, InputAdornment, TextField, TextFieldProps} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {GroupedRoleAssignment} from '../modules/ManageUsers.selectors'

interface Props {
  groupedRoleAssignment: GroupedRoleAssignment
  onChangeContractIds: (contractIds: string) => void
  dataScopeName: string
  disabled: boolean
}

export const RoleContractLookupTextField: React.FC<Props & Omit<TextFieldProps, 'onChange'>> = ({
  dataScopeName,
  onChangeContractIds,
  disabled,
  helperText
}) => {
  const {t} = useTranslation()

  const [text, setText] = useState('')

  const handleLookup = () => {
    if (text.trim().length === 0) {
      return
    }
    onChangeContractIds(text)
    setText('')
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== 'Enter') {
      return
    }

    event.preventDefault()
    handleLookup()
  }

  return (
    <TextField
      label={t(`roleAssignment.dataScope.${dataScopeName}`)}
      variant="standard"
      value={text}
      onChange={(evt) => setText(evt.target.value)}
      onKeyDown={handleKeyDown}
      type="search"
      disabled={disabled}
      error={!!helperText}
      helperText={helperText}
      InputProps={{
        disabled,
        endAdornment:
          text.length > 0 && !disabled ? (
            <InputAdornment position="end" variant="outlined">
              <ButtonBase onClick={handleLookup} data-test-id="button-contract-lookup">
                <AddIcon />
              </ButtonBase>
            </InputAdornment>
          ) : undefined
      }}
    />
  )
}
