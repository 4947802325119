export interface LookupState {
  text: string
  isLoading: boolean
  errorMessage: string | null
}

export const initialTextFieldState: LookupState = {
  text: '',
  isLoading: false,
  errorMessage: null
}

export type TextFieldAction =
  | {type: 'CHANGE_TEXT'; payload: string}
  | {type: 'LOOKUP_REQUEST'}
  | {type: 'LOOKUP_SUCCESS'}
  | {type: 'LOOKUP_FAILURE'; payload: Error; error: boolean}

export const textFieldReducer = (state: LookupState, action: TextFieldAction): LookupState => {
  switch (action.type) {
    case 'CHANGE_TEXT':
      return {
        ...state,
        text: action.payload,
        errorMessage: null
      }

    case 'LOOKUP_REQUEST':
      return {
        ...state,
        isLoading: true,
        errorMessage: null
      }

    case 'LOOKUP_SUCCESS': {
      return {
        text: '',
        isLoading: false,
        errorMessage: null
      }
    }

    case 'LOOKUP_FAILURE':
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.message
      }

    default:
      return state
  }
}
