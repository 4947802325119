import {SelectGrouped, SelectGroupedOption} from '@hconnect/uikit'
import {Field, FieldInputProps, FormikProps, getIn} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useAdminPilotUser} from '../../../common/hooks/useAdminPilotUser'
import {DataScopes} from '../../../modules/ManageUsers.selectors'
import {checkRequired} from '../utils'

type CustomerOrPayerDropdownProps = {
  name: string
  dataScope: string
  options: SelectGroupedOption[]
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}
export const CustomerOrPayerDropdown = ({
  dataScope,
  name,
  options,
  onChange,
  disabled = false
}: CustomerOrPayerDropdownProps) => {
  const {t} = useTranslation()
  const isUserAdminPilot = useAdminPilotUser()

  return (
    <Field
      name={name}
      validate={checkRequired(!isUserAdminPilot, false, t(`roleAssignment.noValue.${dataScope}`))}
    >
      {({
        field,
        form
      }: {
        field: FieldInputProps<string[]>
        form: FormikProps<{commonDataScopes: DataScopes}>
      }) => (
        <SelectGrouped
          {...field}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(event)
            onChange(event)
          }}
          label={t(`roleAssignment.dataScope.${dataScope}`)}
          required={false}
          disabled={disabled}
          margin="dense"
          error={!!getIn(form.errors, name)}
          helperText={getIn(form.errors, name)}
          options={options}
        />
      )}
    </Field>
  )
}
