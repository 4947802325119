import {Typography} from '@hconnect/uikit'
import {
  Button,
  Box,
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  InputAdornment,
  ButtonBase
} from '@material-ui/core/'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ModalWrapper} from '../../components/ModalWrapper'
import {CustomersScopes} from '../../modules/ManageUsers.selectors'

import {useStyles} from './CustomersScopes.styles'

interface ContractsModalProps {
  open: boolean
  onClose(): void
  customerId: string | null
  customersScopes: CustomersScopes[]
  handleSaveContractIds?: (contractIds: string[]) => void
}

export const ContractsModal: React.FC<ContractsModalProps> = ({
  open,
  onClose,
  customerId,
  customersScopes,
  handleSaveContractIds
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const scope = customersScopes.find((scope) => scope.customerId === customerId)

  const [contractIds, setContractIds] = useState([...(scope?.contractIds || [])])
  const [newContractId, setNewContractId] = useState('')
  const [helperText, setHelperText] = useState('')

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    setHelperText('')

    if (event.key !== 'Enter') {
      return
    }

    event.preventDefault()
    addNewContract()
  }

  const addNewContract = () => {
    if (!newContractId || !newContractId.trim()) {
      setHelperText(t('roleAssignment.noValue.contractIds'))
      return
    }
    const contractToAdd = newContractId.trim()
    if (contractIds.includes(contractToAdd)) {
      setHelperText(t('roleAssignment.contractAlreadyExists'))
      return
    }
    setContractIds([...contractIds, contractToAdd])
    setHelperText('')
    setNewContractId('')
  }

  return (
    <ModalWrapper open={open}>
      <Paper style={{maxHeight: '80%', overflow: 'auto'}}>
        <Box m={4}>
          <Typography variant="h2">{t('roleAssignment.contractsModalTitle')}</Typography>
          <TextField
            label={t('roleAssignment.contractNumber')}
            data-test-id="roleAssignment.contractNumber"
            variant="standard"
            value={newContractId}
            onChange={(evt) => setNewContractId(evt.target.value)}
            onKeyDown={handleKeyDown}
            type="search"
            error={!!helperText}
            helperText={helperText}
            InputProps={{
              disabled: false,
              endAdornment:
                newContractId.length > 0 ? (
                  <InputAdornment position="end" variant="outlined">
                    <ButtonBase onClick={addNewContract} data-test-id="button-add-new-contract">
                      <AddIcon />
                    </ButtonBase>
                  </InputAdornment>
                ) : undefined
            }}
          />
          <List>
            {contractIds
              .map((contractId) => (
                <ListItem key={contractId}>
                  <ListItemText primary={t('roleAssignment.contractItemText', {contractId})} />
                  <ListItemSecondaryAction>
                    <Button
                      variant="text"
                      color="secondary"
                      className={classes.deleteButton}
                      data-test-id="delete-contract-button"
                      onClick={() => {
                        setContractIds(contractIds.filter((x) => x !== contractId))
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
              .reverse()}
          </List>
          <Box m={2}>
            <Button variant="text" color="primary" onClick={onClose}>
              {t('roleAssignment.discardChanges')}
            </Button>
            <Button
              color="primary"
              data-test-id="roleAssignment.save"
              onClick={() => {
                /// TODO: REMOVE sometimes when we get time to dispatch a proper action!
                if (scope) {
                  scope.contractIds = contractIds
                }

                handleSaveContractIds?.(contractIds)
                onClose()
              }}
            >
              {t('roleAssignment.save')}
            </Button>
          </Box>
        </Box>
      </Paper>
    </ModalWrapper>
  )
}
