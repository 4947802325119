import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  cardActions: {
    justifyContent: 'space-between',
    height: '71px'
  },
  cardContent: {},
  icon: {
    marginRight: theme.spacing(1)
  },
  pre: {
    fontFamily: 'monospace'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: theme.spacing(6)
  },
  roleType: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  multiSelect: {
    paddingTop: theme.spacing(2.5)
  },
  deleteButton: {
    minWidth: 'auto'
  },
  roleLabel: {
    flexGrow: 1
  },
  myChip: {
    height: 'unset'
  },
  chipAuto: {
    marginLeft: '5px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.main
  },
  chipInternal: {
    backgroundColor: theme.palette.secondary.light
  },
  chipExternal: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.dark
  },
  restrictionHint: {
    padding: '0 16px'
  }
}))
