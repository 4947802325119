import {Localization} from '@hconnect/uikit'

import {isDevelopment} from '../common/Constants'

const props = {
  backend: {
    loader: (filename: string) => {
      if (isDevelopment) {
        console.info(`Load Translation for adminconsole [${filename}]`)
      }
      return import(`./${filename}.json`).catch((err) => {
        if (isDevelopment) {
          console.error(`Could not load Translation for adminconsole [${filename}]`)
        }
        return {err}
      })
    }
  },
  interpolation: {escapeValue: false}
}

export default () => Localization(props)
