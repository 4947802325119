import TextField from '@material-ui/core/TextField'
import {Field} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {checkRequired} from '../utils'

interface Props {
  isRequired: boolean
  errorMessage: string
  dataScopeName: string
  multiSelect: boolean
}

const ReadonlyDataScope: React.FC<Props> = ({
  isRequired,
  errorMessage,
  dataScopeName,
  multiSelect
}) => {
  const {t} = useTranslation()
  const fieldName = `commonDataScopes.${dataScopeName}`

  return (
    <Field
      key={dataScopeName}
      name={fieldName}
      validate={checkRequired(
        isRequired,
        multiSelect,
        t('roleAssignment.dataScopeRequired', {
          dataScope: t(`roleAssignment.dataScope.${dataScopeName}`)
        })
      )}
    >
      {({field, form}: any) => (
        <TextField
          label={t(`roleAssignment.dataScope.${dataScopeName}`)}
          {...field}
          required={isRequired}
          disabled
          margin="normal"
          fullWidth
          error={!!errorMessage}
          helperText={errorMessage}
          value={form.values.commonDataScopes[dataScopeName] || ''}
        />
      )}
    </Field>
  )
}

export default ReadonlyDataScope
