import {Project} from '@hconnect/apiclient'
import {Reducer} from 'react'

import {ProjectAndSites, mapProjectsAndSites} from './Projects'
import {
  FETCH_PROJECTS_AND_SITES_FAILURE,
  FETCH_PROJECTS_AND_SITES_REQUEST,
  FETCH_PROJECTS_AND_SITES_SUCCESS,
  PROJECTS_WITH_SITES,
  ProjectsAndSitesAction
} from './Projects.actions'

type LoadingStatus = 'LOADING' | 'DONE' | 'ERROR'

export interface ProjectsAndSitesState {
  loadingStatus: {[customer: string]: LoadingStatus}
  projectsAndSitesByCustomer: {
    [customer: string]: ProjectAndSites[]
  }
  projectsByCustomer: {
    [customer: string]: Project[]
  }
}

export const defaultProjectsAndSitesReducer: ProjectsAndSitesState = {
  loadingStatus: {},
  projectsAndSitesByCustomer: {},
  projectsByCustomer: {}
}

export const projectsAndSitesReducer: Reducer<ProjectsAndSitesState, ProjectsAndSitesAction> = (
  state,
  action
): ProjectsAndSitesState => {
  switch (action.type) {
    case FETCH_PROJECTS_AND_SITES_REQUEST:
      return {
        ...state,
        loadingStatus: {
          ...state.loadingStatus,
          [action.customerId]: 'LOADING'
        }
      }

    case FETCH_PROJECTS_AND_SITES_FAILURE:
      return {
        ...state,
        loadingStatus: {
          ...state.loadingStatus,
          [action.payload.customerId]: 'ERROR'
        }
      }

    case FETCH_PROJECTS_AND_SITES_SUCCESS:
      return {
        ...state,
        loadingStatus: {
          ...state.loadingStatus,
          [action.payload.customerId]: 'DONE'
        },
        projectsByCustomer: {
          ...state.projectsByCustomer,
          [action.payload.customerId]: action.payload.projects
        }
      }

    case PROJECTS_WITH_SITES:
      return {
        ...state,
        loadingStatus: {
          ...state.loadingStatus,
          [action.payload.customerId]: 'DONE'
        },
        projectsAndSitesByCustomer: {
          ...state.projectsAndSitesByCustomer,
          [action.payload.customerId]: mapProjectsAndSites(
            state.projectsByCustomer[action.payload.customerId],
            action.payload.sites
          )
        }
      }

    default:
      return state
  }
}
