import {Site} from '@hconnect/apiclient'
import {ExpandIcon, InlineCheckbox, Searchfield, Typography} from '@hconnect/uikit'
import {Button, CircularProgress, Collapse, Switch, Tab, Tabs} from '@material-ui/core'
import {LocationOn} from '@material-ui/icons'
import some from 'lodash/some'
import React, {ReactNode, useEffect, useReducer, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ModalWrapper} from '../../components/ModalWrapper'
import {
  ADD_CUSTOMER_SCOPE,
  REMOVE_CUSTOMER_SCOPE,
  customersScopesReducer
} from '../../modules/GroupedRoleAssignmentReducer'
import {CustomersScopes, DataScopes, RoleType} from '../../modules/ManageUsers.selectors'
import {
  ProjectAndSites,
  defaultProjectsAndSitesReducer,
  fetchProjectsAndSites,
  projectsAndSitesReducer
} from '../../modules/Projects'

import {CustomerSideBar} from './CustomerSideBar'
import {useStyles} from './CustomersScopesModal.styles'
import {isProjectsCountry} from './utils'

interface SiteRowProps {
  site: Site
  noBorder?: boolean
  handleChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) => void
  selected: boolean | undefined
  disabled?: boolean
}

const SiteRow: React.FC<SiteRowProps> = ({
  site,
  noBorder,
  handleChangeEvent,
  selected,
  disabled
}) => {
  const classes = useStyles()
  return (
    <div className={!noBorder ? classes.siteRow : classes.siteRowNoBorders}>
      <div className={classes.checkbox}>
        <InlineCheckbox
          checked={selected}
          disabled={disabled}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeEvent(event)}
          data-test-id={`checkbox-for-site-${site.siteNumber}`}
        />
      </div>
      <div className={classes.projectInfoContainer}>
        <div className={classes.siteLocationIcon}>
          <LocationOn />
        </div>
        <div className={classes.projectInfo}>
          <Typography variant="body2" component="span" color="secondary">
            # {site.siteNumber}
          </Typography>
          {site.siteName + ' ' + site.city + ',' + site.street}
        </div>
      </div>
    </div>
  )
}

interface ProjectRowProps {
  project: ProjectAndSites
  handleChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) => void
  selected: boolean | undefined
  disabled?: boolean
  siteRows?: ReactNode
  isSiteInProjectSelected?: boolean
  roleType?: string
}

const ProjectRow: React.FC<ProjectRowProps> = ({
  project,
  handleChangeEvent,
  selected,
  disabled,
  siteRows,
  roleType,
  isSiteInProjectSelected
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(!!isSiteInProjectSelected)
  const classes = useStyles()

  const {projectId, projectName, projectNumber, sites} = project

  return (
    <div key={`${projectId}-${projectName}`} className={classes.projectRow}>
      <div className={classes.projectItem}>
        <div className={classes.projectItemLeft}>
          <div className={classes.checkbox}>
            {roleType !== 'ORDER_PLACER' && (
              <InlineCheckbox
                checked={selected}
                disabled={disabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeEvent(event)}
                data-test-id={`checkbox-for-project-${project.projectNumber}`}
              />
            )}
          </div>
          <div className={classes.projectInfoContainer}>
            <div className={classes.projectInfo}>
              <Typography variant="body2" component="span" color="secondary">
                # {projectNumber}
              </Typography>
              {projectName}
            </div>
          </div>
        </div>
        {sites && sites.length > 0 && (
          <ExpandIcon open={isOpen} onClick={() => setIsOpen(!isOpen)} />
        )}
      </div>
      <Collapse in={isOpen} timeout="auto" unmountOnExit className={classes.projectRowSiteItem}>
        {siteRows}
      </Collapse>
    </div>
  )
}

interface IsProjectSiteSelectedArgs {
  customerId: string
  customersScopes: CustomersScopes[]
  siteId?: string
  projectId?: string
}

const isProjectSelected = ({customerId, customersScopes, projectId}: IsProjectSiteSelectedArgs) => {
  const customerScopes = customersScopes.find(
    (customerScopes) => customerScopes.customerId === customerId
  )
  if (!customerScopes) {
    return false
  }

  if (projectId) {
    return customerScopes.projectIds.includes(projectId)
  }
}

const isSiteSelected = ({customerId, customersScopes, siteId}: IsProjectSiteSelectedArgs) => {
  const customerScopes = customersScopes.find(
    (customerScopes) => customerScopes.customerId === customerId
  )
  if (!customerScopes) {
    return false
  }

  if (siteId) {
    return customerScopes.siteIds.includes(siteId)
  }
}

interface Props {
  open: boolean
  initialCustomer: string | null
  onClose: () => void
  onSave?: (customersScopes: CustomersScopes[]) => void
  roleType: RoleType
  commonDataScopes: DataScopes
  customersScopes: CustomersScopes[]
  canSelectSites: boolean
  canSelectProjects: boolean
}

export const CustomersAndProjectsSitesModal: React.FC<Props> = ({
  open,
  initialCustomer = null,
  onClose,
  onSave,
  roleType,
  commonDataScopes,
  customersScopes: initialCustomersScopes,
  canSelectSites,
  canSelectProjects
}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(initialCustomer)
  const [allProjectsSitesSelectedByCustomerId, setAllProjectsSitesSelected] = useState<{
    [customerId: string]: boolean
  }>({})
  const [searchInput, setSearchInput] = useState<string>()

  const [projectsAndSites, dispatchProjectsAndSitesUpdate] = useReducer(
    projectsAndSitesReducer,
    defaultProjectsAndSitesReducer
  )

  const [customersScopes, dispatchCustomersScopesAction] = useReducer(
    customersScopesReducer,
    initialCustomersScopes
  )

  const [showSelectedProjects, setShowSelectedProjects] = useState(false)

  const countryId = commonDataScopes.countryId

  useEffect(() => {
    if (selectedCustomerId !== null || !initialCustomersScopes[0]) {
      return
    }

    setSelectedCustomerId(initialCustomersScopes[0].customerId)
  }, [initialCustomersScopes, selectedCustomerId])

  useEffect(() => {
    customersScopes.forEach(({customerId}) => {
      void fetchProjectsAndSites(
        customerId,
        {
          countryId: countryId as string,
          businessLine: commonDataScopes.businessLine as string,
          orgUnitId: commonDataScopes.orgUnitId as string
        },
        dispatchProjectsAndSitesUpdate
      )
    })

    const defaultAllProjectsSitesSelectedState = initialCustomersScopes.reduce(
      (acc: Record<string, boolean>, {customerId, siteIds, projectIds}) => {
        acc[customerId] = siteIds.length === 0 && projectIds.length === 0
        return acc
      },
      {}
    )

    setAllProjectsSitesSelected(defaultAllProjectsSitesSelectedState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!selectedCustomerId) {
    return null
  }

  // FIXME add properly working loading state
  // if (customersIsFetching) {
  //   return (
  //     <ModalWrapper open={open}>
  //       <div className={classes.paper}>
  //         <div className={classes.loadingContainer}>
  //           xDDD
  //           <CircularProgress />
  //         </div>
  //       </div>
  //     </ModalWrapper>
  //   )
  // }

  interface ToggleProjectToCustomerArgs {
    event: React.ChangeEvent<HTMLInputElement>
    projectId: string
    customerId: string
    sites?: Site[]
  }

  const toggleProjectToCustomer = ({event, projectId, customerId}: ToggleProjectToCustomerArgs) => {
    if (event.target.checked) {
      dispatchCustomersScopesAction({
        type: ADD_CUSTOMER_SCOPE,
        payload: {projectId, customerId}
      })
    } else {
      dispatchCustomersScopesAction({
        type: REMOVE_CUSTOMER_SCOPE,
        payload: {projectId, customerId}
      })
    }
  }

  const toggleSiteToCustomer = (
    event: React.ChangeEvent<HTMLInputElement>,
    siteId: string,
    customerId: string
  ) => {
    if (event.target.checked) {
      dispatchCustomersScopesAction({
        type: ADD_CUSTOMER_SCOPE,
        payload: {
          siteId,
          customerId
        }
      })
    } else {
      dispatchCustomersScopesAction({
        type: REMOVE_CUSTOMER_SCOPE,
        payload: {siteId, customerId}
      })
    }
  }

  const renderProjectSite = (project: ProjectAndSites) => {
    const currentScope = customersScopes.find((scope) => scope.customerId === selectedCustomerId)

    const isSiteInProjectSelected = some(project.sites, (site: Site) =>
      isSiteSelected({
        customerId: selectedCustomerId,
        customersScopes,
        siteId: site.siteId
      })
    )

    if (project.projectId) {
      const projectSelected = isProjectSelected({
        customerId: selectedCustomerId,
        customersScopes,
        projectId: project.projectId
      })

      if (roleType === 'ORDER_PLACER' && project.sites?.length === 0) {
        return null
      }

      return (
        <ProjectRow
          key={project.projectId}
          disabled={
            (!projectSelected &&
              !!currentScope?.siteIds.length &&
              !!currentScope.projectIds.length) ||
            !canSelectProjects
          }
          project={project}
          isSiteInProjectSelected={!projectSelected && isSiteInProjectSelected}
          handleChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) =>
            toggleProjectToCustomer({
              event: e,
              projectId: project.projectId as string,
              customerId: selectedCustomerId,
              sites: project.sites
            })
          }
          roleType={roleType}
          selected={projectSelected}
          siteRows={project.sites?.map((site: Site) => {
            const siteSelected = isSiteSelected({
              customerId: site.customerId,
              customersScopes,
              siteId: site.siteId
            })
            return (
              <SiteRow
                key={`${site.siteId}-${site.siteName}`}
                site={site}
                noBorder
                handleChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) =>
                  toggleSiteToCustomer(e, site.siteId, site.customerId)
                }
                selected={siteSelected}
                disabled={
                  (currentScope?.projectIds && currentScope?.projectIds.length > 1) ||
                  !canSelectSites
                }
              />
            )
          })}
        />
      )
    }

    if (project.sites) {
      return project.sites.map((site: Site) => {
        const selected = isSiteSelected({
          customerId: site.customerId,
          customersScopes,
          siteId: site.siteId
        })
        return (
          <SiteRow
            key={`${site.siteId}-${site.siteName}`}
            site={site}
            disabled={
              (currentScope?.projectIds && currentScope?.projectIds.length > 1) || !canSelectSites
            }
            handleChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) =>
              toggleSiteToCustomer(e, site.siteId, site.customerId)
            }
            selected={selected}
          />
        )
      })
    }
  }

  const renderProjectsAndSites = (showSelectedProjects: boolean) => {
    if (!selectedCustomerId) {
      return
    }

    const projectsAndSitesBySelectedCustomer =
      projectsAndSites.projectsAndSitesByCustomer[selectedCustomerId]

    if (!projectsAndSitesBySelectedCustomer) {
      return null
    }

    const filterSites = (projectAndSites: ProjectAndSites) => {
      if (!searchInput || projectAndSites.projectId || !projectAndSites.sites) {
        return projectAndSites
      }

      const sitesWithoutProject = projectAndSites.sites

      const searchString = searchInput.toLowerCase()
      const filteredSites = sitesWithoutProject.filter((site: Site) => {
        const siteNameLowerCased = site.siteName?.toLowerCase()
        return siteNameLowerCased?.includes(searchString) || site.siteNumber?.includes(searchString)
      })

      return {
        sites: filteredSites
      }
    }

    const filterProjects = (projectAndSites: ProjectAndSites) => {
      if (!searchInput) {
        return projectAndSites
      }

      const searchString = searchInput.toLowerCase()

      const siteInProjectMatches = some(projectAndSites.sites, (site: Site) => {
        const siteNameLowerCased = site.siteName?.toLowerCase()
        return siteNameLowerCased?.includes(searchString) || site.siteNumber?.includes(searchString)
      })

      const projectNameLowerCased = projectAndSites.projectName?.toLowerCase()
      const hasMatch =
        projectNameLowerCased?.includes(searchString) ||
        projectAndSites.projectNumber?.includes(searchString) ||
        siteInProjectMatches
      return hasMatch
    }

    if (!showSelectedProjects) {
      return projectsAndSitesBySelectedCustomer
        .map((projectsAndSites: ProjectAndSites) => filterSites(projectsAndSites))
        .filter((projectAndSites: ProjectAndSites) => filterProjects(projectAndSites))
        .map(renderProjectSite)
    }

    const selectedProjectsAndSites = projectsAndSitesBySelectedCustomer
      .map((projectsAndSites: ProjectAndSites) => filterSites(projectsAndSites))
      .filter((projectAndSites: ProjectAndSites) => filterProjects(projectAndSites))
      .map((projectAndSites: ProjectAndSites) => {
        if (projectAndSites.projectId || !projectAndSites.sites) {
          return projectAndSites
        }

        const sitesWithoutProject = projectAndSites.sites

        const filteredSitesWithoutProject = sitesWithoutProject.filter((site: Site) =>
          isSiteSelected({
            customerId: selectedCustomerId,
            customersScopes,
            siteId: site.siteId
          })
        )

        return {
          sites: filteredSitesWithoutProject
        }
      })
      .filter(({projectId, sites}) => {
        if (!projectId) {
          return true
        }

        const projectSelected = isProjectSelected({
          customerId: selectedCustomerId,
          customersScopes,
          projectId
        })

        const isSiteInProjectSelected = some(sites, (site: Site) =>
          isSiteSelected({
            customerId: selectedCustomerId,
            customersScopes,
            siteId: site.siteId
          })
        )

        return projectSelected || isSiteInProjectSelected
      })
      .map(renderProjectSite)

    return selectedProjectsAndSites
  }

  const handleSearch = (
    showSelectedProjects: boolean,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
    renderProjectsAndSites(showSelectedProjects)
  }

  const renderProjectsAndSitesSelection = () => {
    const currentCustomersScopes = customersScopes.find(
      (customersScopes) => customersScopes.customerId === selectedCustomerId
    )
    const numberOfSelectedProjectsSites = currentCustomersScopes
      ? currentCustomersScopes.siteIds.length + currentCustomersScopes.projectIds.length
      : 0

    const allProjectsSitesLabel = () => {
      if (roleType === 'ORDER_PLACER') {
        return 'customersScopes.allDestinationsSelectedLabel'
      }

      return !isProjectsCountry(countryId as string)
        ? 'roleAssignment.customersAndSitesModal.tab_allSites'
        : 'roleAssignment.customersAndSitesModal.tab_allProjectsSites'
    }

    const selectedProjectsSitesLabel = () => {
      if (roleType === 'ORDER_PLACER') {
        return 'roleAssignment.customersAndSitesModal.tab_selectedDestinations'
      }

      return !isProjectsCountry(countryId as string)
        ? 'roleAssignment.customersAndSitesModal.tab_selectedSites'
        : 'roleAssignment.customersAndSitesModal.tab_selectedProjectsSites'
    }

    return (
      <>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          color="primary"
          value={showSelectedProjects ? 1 : 0}
          onChange={(_, value) => {
            setShowSelectedProjects(value === 1)
          }}
        >
          <Tab label={t(allProjectsSitesLabel())} />
          <Tab
            label={t(selectedProjectsSitesLabel(), {
              count: numberOfSelectedProjectsSites
            })}
          />
        </Tabs>
        <div className={classes.sitesDisclaimer}>
          {t(
            roleType === 'ORDER_PLACER'
              ? 'roleAssignment.customersAndSitesModal.destinations_disclaimer'
              : 'roleAssignment.customersAndSitesModal.sites_disclaimer'
          )}
          <Searchfield
            inputProps={{
              style: {
                minWidth: 180
              }
            }}
            placeholder={t(
              roleType === 'ORDER_PLACER'
                ? 'roleAssignment.customersAndSitesModal.search_destination'
                : 'roleAssignment.customersAndSitesModal.search'
            )}
            value={searchInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleSearch(showSelectedProjects, event)
            }
          />
        </div>
        <div className={classes.sitesContainer}>{renderProjectsAndSites(showSelectedProjects)}</div>
      </>
    )
  }

  const renderAllProjectsSitesSelected = (
    selectedCustomerId: string,
    numberOfProjectsAndSites: () => number
  ) => (
    <div className={classes.allSitesSelectedPlaceholderContainer}>
      <Typography variant="h3" className={classes.allSitesSelectedPlaceholderInfoText}>
        {numberOfProjectsAndSites() === 0
          ? t('roleAssignment.customersAndSitesModal.noProjectsSitesAvailablePlaceholder')
          : t('roleAssignment.customersAndSitesModal.allProjectsSitesSelectedPlaceholder', {
              count: numberOfProjectsAndSites()
            })}
      </Typography>
      {numberOfProjectsAndSites() > 0 ? (
        <Button
          variant="text"
          color="primary"
          data-test-id="roleAssignment.customersAndSitesModal.assignSpecificProjectsSitesButton"
          onClick={() => {
            setAllProjectsSitesSelected({
              ...allProjectsSitesSelectedByCustomerId,
              [selectedCustomerId]: false
            })
          }}
        >
          {t('roleAssignment.customersAndSitesModal.assignSpecificProjectsSitesButton')}
        </Button>
      ) : null}
    </div>
  )

  const numberOfProjectsAndSites = (): number => {
    const projectsAndSitesBySelectedCustomer =
      projectsAndSites.projectsAndSitesByCustomer[selectedCustomerId]

    if (!projectsAndSitesBySelectedCustomer) {
      return 0
    }

    const allProjects: number = projectsAndSitesBySelectedCustomer.filter(
      (item: ProjectAndSites) => item.projectId !== undefined
    ).length

    const allSites = projectsAndSitesBySelectedCustomer
      .map((item: ProjectAndSites) => item.sites)
      .reduce((sum, currentItem): number => sum + currentItem!.length, 0)

    return allProjects + allSites
  }

  const renderProjectsArea = () => {
    if (
      projectsAndSites.loadingStatus[selectedCustomerId] &&
      projectsAndSites.loadingStatus[selectedCustomerId] === 'LOADING'
    ) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )
    }

    return allProjectsSitesSelectedByCustomerId[selectedCustomerId]
      ? renderAllProjectsSitesSelected(selectedCustomerId, numberOfProjectsAndSites)
      : renderProjectsAndSitesSelection()
  }

  const allProjectsAndSitesSelectedLabel = (() => {
    if (roleType === 'ORDER_PLACER') {
      return t('customersScopes.allDestinationsSelectedLabel')
    }
    if (!isProjectsCountry(countryId as string)) {
      return t('customersScopes.allProjectsSelectedLabel')
    }
    return t('customersScopes.allProjectsAndSitesSelectedLabel')
  })()

  return (
    <ModalWrapper open={open}>
      <div className={classes.paper}>
        <div className={classes.customerSelector}>
          {customersScopes.map((customerScopes: CustomersScopes) => {
            const {customerId} = customerScopes
            const isSelected = selectedCustomerId === customerId

            return (
              <CustomerSideBar
                key={customerId}
                customerId={customerId}
                isSelected={isSelected}
                setSelectedCustomerId={setSelectedCustomerId}
              />
            )
          })}
        </div>
        <div className={classes.sites}>
          <div className={classes.sitesSelectionHeader}>
            <div className={classes.sitesSelectionHeaderSide}>
              <Typography variant="h3" className={classes.customersText}>
                {allProjectsAndSitesSelectedLabel}
              </Typography>
              <Switch
                checked={allProjectsSitesSelectedByCustomerId[selectedCustomerId] || false}
                disabled={numberOfProjectsAndSites() === 0}
                color={numberOfProjectsAndSites() === 0 ? 'primary' : 'secondary'}
                className={classes.switchStyle}
                onChange={(event) => {
                  setAllProjectsSitesSelected({
                    ...allProjectsSitesSelectedByCustomerId,
                    [selectedCustomerId]: event.target.checked
                  })
                }}
              />
            </div>
            <div className={classes.sitesSelectionHeaderSide}>
              <Button variant="text" color="primary" onClick={onClose}>
                {t('roleAssignment.discardChanges')}
              </Button>
              <Button
                color="primary"
                data-test-id="projectsAssignments.save"
                onClick={() => {
                  Object.keys(allProjectsSitesSelectedByCustomerId).forEach((customerId) => {
                    if (!allProjectsSitesSelectedByCustomerId[customerId]) {
                      return
                    }

                    const customerIndex = customersScopes.findIndex(
                      (customerScopes) => customerScopes.customerId === customerId
                    )
                    if (customerIndex > -1) {
                      customersScopes[customerIndex].siteIds = []
                      customersScopes[customerIndex].projectIds = []
                    }
                  })
                  onSave?.(customersScopes)
                  onClose()
                }}
              >
                {t('roleAssignment.save')}
              </Button>
            </div>
          </div>
          {renderProjectsArea()}
        </div>
      </div>
    </ModalWrapper>
  )
}
