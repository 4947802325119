import {User} from '@hconnect/apiclient'
import {createSelector} from 'reselect'

import {AppState} from '../App.reducer'

import {Permission} from './LoggedInUser'

export const selectLoggedInUserProfile = (state: AppState): User | null =>
  state.loggedInUser.profile
export const selectLoggedInUserPermissions = (state: AppState): Permission[] =>
  state.loggedInUser.permissions

const createSelectorForLoggedInUserPermissionsOfType = (type: string) =>
  createSelector(selectLoggedInUserPermissions, (permissions) =>
    permissions.filter((permission) => permission.permissionType === type)
  )

export const selectLoggedInUserAssignRolesPermissions =
  createSelectorForLoggedInUserPermissionsOfType('ASSIGN_ROLES')

export const selectLoggedInUserChangeUsersPermissions =
  createSelectorForLoggedInUserPermissionsOfType('CHANGE_USERS')
