import {getProjects, getSites, Project, Site} from '@hconnect/apiclient'

import {api} from '../App.store'

export const FETCH_PROJECTS_AND_SITES_REQUEST = 'FETCH_PROJECTS_AND_SITES_REQUEST'
export const FETCH_PROJECTS_AND_SITES_FAILURE = 'FETCH_PROJECTS_AND_SITES_FAILURE'
export const FETCH_PROJECTS_AND_SITES_SUCCESS = 'FETCH_PROJECTS_AND_SITES_SUCCESS'
export const PROJECTS_WITH_SITES = 'PROJECTS_WITH_SITES'

interface FetchProjectsAndSitesRequest {
  type: typeof FETCH_PROJECTS_AND_SITES_REQUEST
  customerId: string
}

interface ProjectsAndSitesActionErrorPayload {
  customerId: string
  message: string
}

interface FetchProjectsAndSitesFailure {
  type: typeof FETCH_PROJECTS_AND_SITES_FAILURE
  payload: ProjectsAndSitesActionErrorPayload
}

interface ProjectsAndSitesActionSuccessPayload {
  customerId: string
  projects: Project[]
}

interface FetchProjectsAndSitesSuccess {
  type: typeof FETCH_PROJECTS_AND_SITES_SUCCESS
  payload: ProjectsAndSitesActionSuccessPayload
}

interface ProjectsWithSitesActionPayload {
  customerId: string
  sites: Site[]
}

interface ProjectsWithSites {
  type: typeof PROJECTS_WITH_SITES
  payload: ProjectsWithSitesActionPayload
}

export type ProjectsAndSitesAction =
  | FetchProjectsAndSitesRequest
  | FetchProjectsAndSitesSuccess
  | FetchProjectsAndSitesFailure
  | ProjectsWithSites

const fetchProjectsAndSitesRequest = (customerId: string): FetchProjectsAndSitesRequest => ({
  type: FETCH_PROJECTS_AND_SITES_REQUEST,
  customerId
})

const fetchProjectsAndSitesFailure = (
  payload: ProjectsAndSitesActionErrorPayload
): FetchProjectsAndSitesFailure => ({
  type: FETCH_PROJECTS_AND_SITES_FAILURE,
  payload
})

const fetchProjectsAndSitesSuccess = (
  payload: ProjectsAndSitesActionSuccessPayload
): FetchProjectsAndSitesSuccess => ({
  type: FETCH_PROJECTS_AND_SITES_SUCCESS,
  payload
})

export const fetchProjectsAndSites = async (
  customerId: string,
  filters: {
    countryId: string
    orgUnitId: string
    businessLine: string
  },
  dispatch: React.Dispatch<ProjectsAndSitesAction>
) => {
  try {
    dispatch(fetchProjectsAndSitesRequest(customerId))
    const {data: projects} = await getProjects(api)({customerId, ...filters})
    const distinctProjects = new Array(
      ...new Set(projects.map((project) => project.projectId))
    ).map((projectId) => projects.find((project) => project.projectId === projectId) as Project)

    dispatch(fetchProjectsAndSitesSuccess({customerId, projects: distinctProjects}))
  } catch (err) {
    dispatch(fetchProjectsAndSitesFailure({customerId, message: (err as Error).message}))
  }

  try {
    dispatch(fetchProjectsAndSitesRequest(customerId))
    const {data: sites} = await getSites(api)({customerId, ...filters})

    const distinctSites = new Array(...new Set(sites.map((site) => site.siteId))).map(
      (siteId) => sites.find((site) => site.siteId === siteId) as Site
    )

    dispatch({
      type: PROJECTS_WITH_SITES,
      payload: {
        customerId,
        sites: distinctSites
      }
    })
  } catch (err) {
    dispatch(fetchProjectsAndSitesFailure({customerId, message: (err as Error).message}))
  }
}
