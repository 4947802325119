import {Project, Site} from '@hconnect/apiclient'

export * from './Projects.actions'
export * from './Projects.reducer'

export interface ProjectWithSites extends Project {
  sites: Site[]
}

export interface SitesWithoutProjects {
  sites: Site[]
}

interface ProjectWithSitesMapItem {
  [key: string]: ProjectWithSites
}

export type ProjectAndSites = Partial<ProjectWithSites>

export const mapProjectsAndSites = (projects: Project[], sites: Site[]): ProjectAndSites[] => {
  const projectsById: ProjectWithSitesMapItem = Object.assign(
    {},
    ...(projects || []).map((project: Project) => ({
      [project.projectId]: {
        ...project,
        sites: []
      }
    }))
  )

  sites &&
    sites.forEach((site: Site) => site.projectId && projectsById[site.projectId]?.sites.push(site))

  const sitesWithoutProjectIds: SitesWithoutProjects = {
    sites: []
  }

  sites &&
    sites
      .filter((site: Site) => site.projectId === null || site.projectId === undefined)
      .forEach((site: Site) => sitesWithoutProjectIds.sites.push(site))

  const projectsWithSites: ProjectAndSites[] = [
    ...Object.values(projectsById),
    sitesWithoutProjectIds
  ]

  return projectsWithSites
}
