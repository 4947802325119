import {MenuItem, TextField, TextFieldProps} from '@material-ui/core'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {GroupedRoleAssignment} from '../modules/ManageUsers.selectors'

interface Props {
  groupedRoleAssignment: GroupedRoleAssignment
  onChange: (commentCategory: string) => void
  dataScopeName: string
  disabled: boolean
}

export const RoleCommentCategoryLookupSelect: React.FC<
  Props & Omit<TextFieldProps, 'onChange'>
> = ({dataScopeName, onChange, disabled, ...props}) => {
  const {t} = useTranslation()

  const [text, setText] = useState('')

  const handleChange = (text: string) => {
    if (text.trim().length === 0) {
      return
    }
    onChange(text)
    setText('')
  }

  return (
    <TextField
      select
      {...props}
      label={t(`roleAssignment.dataScope.${dataScopeName}`)}
      data-test-id={`${dataScopeName}-textField`}
      variant="standard"
      value={text}
      onChange={(evt) => handleChange(evt.target.value)}
      type="search"
      disabled={disabled}
    >
      <MenuItem value="">None</MenuItem>
      {props.children}
    </TextField>
  )
}
