import {ClientInstance} from '@hconnect/apiclient'

import {api} from '../App.store'
import {prepareAssignRolesPayload} from '../layouts/Roles/utils'

import {
  GroupedRoleAssignment,
  RoleUpdateOperation,
  expandGroupedRoleAssignments,
  getUpdateOperationsFromAssignments,
  groupRoleAssignments,
  RoleAssignment
} from './ManageUsers.selectors'

export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST'
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS'
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE'

interface FetchRolesRequest {
  type: typeof FETCH_ROLES_REQUEST
  meta: {
    userId: string
  }
}
interface FetchRolesSuccess {
  type: typeof FETCH_ROLES_SUCCESS
  payload: RoleAssignment[]
  meta: {
    userId: string
  }
}
interface FetchRolesFailure {
  type: typeof FETCH_ROLES_FAILURE
  payload: Error
  error: true
  meta: {
    userId: string
  }
}

export type RolesAction = FetchRolesRequest | FetchRolesSuccess | FetchRolesFailure

export const fetchRolesForUser = async (userId: string): Promise<RoleAssignment[]> => {
  const response = await api.get(`/roles?userId=${userId}`)
  const {data: roles} = response
  return roles
}

const getUpdateOperations = (
  roleAssignments: RoleAssignment[],
  groupedRoleAssignment: GroupedRoleAssignment
): RoleUpdateOperation[] => {
  const sourceRoleAssignments =
    roleAssignments.filter((roleAssignment) =>
      groupedRoleAssignment.sourceRolesIds.includes(roleAssignment.id)
    ) || []

  const targetRoleAssignments = expandGroupedRoleAssignments(groupedRoleAssignment)

  return getUpdateOperationsFromAssignments(sourceRoleAssignments, targetRoleAssignments)
}

const callApi = async (
  api: ClientInstance,
  operation: RoleUpdateOperation
): Promise<RoleAssignment | null> => {
  const roleId = operation.payload.id

  switch (operation.type) {
    case 'PUT': {
      const response = await api.put<RoleAssignment>(
        `/roles/${roleId}`,
        prepareAssignRolesPayload(operation.payload)
      )
      const {data} = response
      return data
    }

    case 'POST': {
      const payload: Omit<RoleAssignment, 'id'> = operation.payload
      const response = await api.post<RoleAssignment>('/roles', prepareAssignRolesPayload(payload))
      const {data} = response
      return data
    }

    case 'DELETE': {
      await api.delete(`/roles/${roleId}`)
      return null
    }

    default:
      return null
  }
}

const executeOperations = async (
  api: ClientInstance,
  operations: RoleUpdateOperation[]
): Promise<void> => {
  const operationPromises = operations.map(async (operation) => await callApi(api, operation))
  await Promise.all(operationPromises)
}

export const updateAssignments = async (
  sourceRoleAssignments: RoleAssignment[],
  targetRoleAssignments: RoleAssignment[]
): Promise<void> => {
  const operations = getUpdateOperationsFromAssignments(
    sourceRoleAssignments,
    targetRoleAssignments
  )
  return executeOperations(api, operations)
}

export const updateRoleAssignment = async (
  roleAssignments: RoleAssignment[],
  groupedRoleAssignment: GroupedRoleAssignment
): Promise<void> => {
  const operations = getUpdateOperations(roleAssignments, groupedRoleAssignment)
  return executeOperations(api, operations)
}

export const deleteRoleAssignment = async (
  groupedRoleAssignment: GroupedRoleAssignment
): Promise<void> => {
  const operations = groupedRoleAssignment.sourceRolesIds.map(
    (x) =>
      ({
        type: 'DELETE',
        payload: {
          id: x
        }
      }) as RoleUpdateOperation
  )
  return executeOperations(api, operations)
}

export const resetRoleAssignments = (roleAssignments: RoleAssignment[]) => {
  const groupedRoleAssignments = groupRoleAssignments(roleAssignments)
  return Promise.all(
    groupedRoleAssignments.map((groupedRoleAssignment) =>
      deleteRoleAssignment(groupedRoleAssignment)
    )
  )
}
