import {Box, Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'

import {RoleCommentCategoryLookupChip} from '../../components/Role/RoleCommentCategoryLookupChip'
import {GroupedRoleAssignment} from '../../modules/ManageUsers.selectors'

const useStyles = makeStyles(() => ({
  deleteButton: {
    minWidth: 'auto'
  },
  container: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#7F9BA6',
    borderRadius: '3px',
    marginTop: '5px',
    padding: '6px 6px 6px 8px',
    marginBottom: '-1px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  }
}))

interface Props {
  groupedRoleAssignment: GroupedRoleAssignment
  isEditing: boolean
  handleDeleteCommentCategory?(commentCategory: string): void
}

export const CommentCategories: React.FC<Props> = ({
  groupedRoleAssignment,
  isEditing,
  handleDeleteCommentCategory
}) => {
  const classes = useStyles()

  const {commentCategories} = groupedRoleAssignment.variantDataScopes

  if (!commentCategories || commentCategories.length === 0) {
    return null
  }

  return (
    <Box>
      <Box>
        {commentCategories?.map((commentCategory) => (
          <Box key={commentCategory} className={classes.container}>
            <RoleCommentCategoryLookupChip
              key={commentCategory}
              commentCategory={commentCategory}
            />
            {isEditing && (
              <Button
                variant="text"
                color="secondary"
                className={classes.deleteButton}
                onClick={() => {
                  handleDeleteCommentCategory?.(commentCategory)
                }}
              >
                <DeleteIcon />
              </Button>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
