import {trackEvent} from '@hconnect/common/logging/Analytics'
import {LoadingButton} from '@hconnect/uikit'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import React, {useReducer} from 'react'
import {useTranslation} from 'react-i18next'

import {
  DELETE_UNSUPPORTED_SCOPE,
  groupedRoleAssignmentReducer
} from '../../../modules/GroupedRoleAssignmentReducer'
import {GroupedRoleAssignment, RoleAssignment} from '../../../modules/ManageUsers.selectors'
import {updateAssignments} from '../../../modules/Roles.actions'

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    minWidth: 'auto'
  },
  controlForm: {
    paddingTop: theme.spacing(2.5)
  },
  dialogContentText: {
    color: theme.palette.text.primary
  },
  scopesList: {
    padding: 0,
    maxHeight: 200,
    overflow: 'auto'
  },
  scopesListItem: {
    padding: '0 48px 0 0',
    wordBreak: 'break-all',
    background: '#F8F8F8'
  },
  scopesListItemEven: {
    padding: '0 48px 0 0',
    wordBreak: 'break-all'
  },
  buttonAction: {
    right: 0
  }
}))

export const DeleteDataScope = ({
  dataScopeName,
  groupedRoleAssignment,
  roleAssignments
}: {
  dataScopeName: string
  dataScopeValues: string[]
  groupedRoleAssignment: GroupedRoleAssignment
  roleAssignments: RoleAssignment[]
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const [roleState, dispatchRoleStateUpdate] = useReducer(
    groupedRoleAssignmentReducer,
    groupedRoleAssignment
  )
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  const [loading, setLoading] = React.useState(false)
  const [showDeleteScopeDialog, setShowDeleteScopeDialog] = React.useState(false)
  const [dataScopeValueToDelete, setDataScopeValueToDelete] = React.useState('')

  const dataScopeValues = roleState.unsupportedDataScopes[dataScopeName] as string[]

  const translatedDataScopeName = t(`roleAssignment.dataScope.${dataScopeName}`)

  const label =
    translatedDataScopeName === `roleAssignment.dataScope.${dataScopeName}`
      ? dataScopeName
      : translatedDataScopeName

  const openDeleteDialog = (dataScopeValue: string): void => {
    setDataScopeValueToDelete(dataScopeValue)
    setShowDeleteScopeDialog(true)
  }

  const handleDelete = async () => {
    const sourceRoleAssignments =
      roleAssignments.filter((roleAssignment) =>
        groupedRoleAssignment.sourceRolesIds.includes(roleAssignment.id)
      ) || []

    if (sourceRoleAssignments.length > 1)
      throw new Error('Multiple sources are not supported for unsupported DataScopes')

    const scopes = groupedRoleAssignment.unsupportedDataScopes[dataScopeName] as string[]

    const newScopes = scopes.filter((x) => x !== dataScopeValueToDelete)

    const targetRoleAssignments = sourceRoleAssignments.map((assignment) => {
      return {
        ...assignment,
        dataScope: {
          ...assignment.dataScope,
          [dataScopeName]: newScopes
        }
      }
    })

    try {
      await updateAssignments(sourceRoleAssignments, targetRoleAssignments)
    } catch (e) {
      const error = e as AxiosError
      console.error(error)
      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: error.response?.status,
        component: 'DeleteDataScope.tsx',
        endpoint: error.response?.config?.url
      })
    }

    dispatchRoleStateUpdate({
      type: DELETE_UNSUPPORTED_SCOPE,
      payload: {
        dataScopeName,
        dataScopeValue: dataScopeValueToDelete
      }
    })
  }

  const deleteDialog = (
    <Dialog
      data-test-id="delete-scope-dialog"
      open={showDeleteScopeDialog}
      onClose={() => setShowDeleteScopeDialog(false)}
    >
      <DialogTitle>{t('roleAssignment.deleteScopeBox.' + dataScopeName + '.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          {t('roleAssignment.deleteScopeBox.' + dataScopeName + '.confirmationMessage', {
            dataScopeValueToDelete
          })}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setShowDeleteScopeDialog(false)} variant="text" color="primary">
          {t('roleAssignment.deleteScopeBox.cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={async () => {
            try {
              setLoading(true)
              await handleDelete()
              enqueueSnackbar('Success', {
                // eslint-disable-next-line react/display-name
                action: () => (
                  <Button onClick={() => closeSnackbar()} variant="text" color="primary">
                    {t('roleAssignment.deleteScopeBox.' + dataScopeName + '.successMessage', {
                      dataScopeValueToDelete
                    })}
                  </Button>
                ),
                variant: 'success'
              })
            } catch {
              enqueueSnackbar(t('roleAssignment.deleteScopeBox.' + dataScopeName + '.error'), {
                variant: 'error'
              })
            }
            setLoading(false)
            setShowDeleteScopeDialog(false)
          }}
          variant="text"
          color="primary"
          data-test-id="button-delete-scope-confirm"
        >
          {t('roleAssignment.deleteScopeBox.' + dataScopeName + '.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )

  return (
    <FormControl key={dataScopeName} margin="normal" fullWidth className={classes.controlForm}>
      <InputLabel shrink variant="standard">
        {label}
      </InputLabel>
      <List className={classes.scopesList}>
        {dataScopeValues.map((dataScopeValue, index) => (
          <ListItem
            key={dataScopeValue}
            className={index % 2 ? classes.scopesListItem : classes.scopesListItemEven}
          >
            <ListItemText primary={dataScopeValue} />
            {dataScopeValues.length > 1 && (
              <ListItemSecondaryAction className={classes.buttonAction}>
                <Button
                  variant="text"
                  color="secondary"
                  className={classes.deleteButton}
                  data-test-id={`delete-order-${dataScopeValue}`}
                  onClick={() => {
                    openDeleteDialog(dataScopeValue)
                  }}
                >
                  <DeleteIcon />
                </Button>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
      {deleteDialog}
    </FormControl>
  )
}
