import {getPlantById} from '@hconnect/apiclient'
import {ButtonBase, InputAdornment, TextField, TextFieldProps} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React, {Reducer, useReducer, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {api} from '../App.store'
import {GroupedRoleAssignment} from '../modules/ManageUsers.selectors'

import {
  initialTextFieldState,
  LookupState,
  TextFieldAction,
  textFieldReducer
} from './TextFieldReducer'

interface Props {
  groupedRoleAssignment: GroupedRoleAssignment
  onChangePlantIds: (plantIds: string) => void
  dataScopeName: string
  disabled: boolean
}

export const RolePlantLookupTextField: React.FC<Props & Omit<TextFieldProps, 'onChange'>> = ({
  dataScopeName,
  onChangePlantIds,
  disabled,
  helperText,
  groupedRoleAssignment,
  ...props
}) => {
  const {t} = useTranslation()

  const [text, setText] = useState('')
  const [state, dispatch] = useReducer<Reducer<LookupState, TextFieldAction>>(
    textFieldReducer,
    initialTextFieldState
  )
  const {countryId} = groupedRoleAssignment.commonDataScopes
  const handleLookup = async () => {
    dispatch({type: 'LOOKUP_REQUEST'})
    if (text.trim().length === 0) {
      return
    }
    if (dataScopeName === 'plantIds') {
      try {
        const plant = await getPlantById(api)(text, countryId as string)
        if (
          groupedRoleAssignment.variantDataScopes.plantIds?.some(
            (plantId) => plantId === plant.plantId
          )
        ) {
          dispatch({
            type: 'LOOKUP_FAILURE',
            payload: new Error(t('roleAssignment.duplicatedPlant')),
            error: true
          })
          return
        }

        if (plant?.plantId && plant?.country === countryId) {
          dispatch({type: 'LOOKUP_SUCCESS'})
          onChangePlantIds(plant.plantId)
          return
        }
        throw new Error('Invalid country!')
      } catch (err) {
        dispatch({
          type: 'LOOKUP_FAILURE',
          payload: new Error(t(`roleAssignment.noLookupResult.${dataScopeName}`)),
          error: true
        })
        return
      }
    }
    setText('')
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== 'Enter') {
      return
    }

    event.preventDefault()
    void handleLookup()
  }

  return (
    <TextField
      {...props}
      label={t(`roleAssignment.dataScope.${dataScopeName}`)}
      data-test-id={`${dataScopeName}-textField`}
      variant="standard"
      value={text}
      onChange={(evt) => setText(evt.target.value)}
      onKeyDown={handleKeyDown}
      type="search"
      disabled={disabled}
      error={!!state.errorMessage}
      helperText={state.errorMessage || helperText}
      InputProps={{
        disabled,
        endAdornment:
          text.length > 0 && !disabled ? (
            <InputAdornment position="end" variant="outlined">
              <ButtonBase onClick={handleLookup} data-test-id="button-plant-lookup">
                <AddIcon />
              </ButtonBase>
            </InputAdornment>
          ) : undefined
      }}
    />
  )
}
