import {TextField} from '@material-ui/core'
import {Field} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CHANGE_COMMON_DATASCOPE_VALUE} from '../../../modules/GroupedRoleAssignmentReducer'
import {checkRequired} from '../utils'

interface Props {
  isRequired: boolean
  errorMessage: string
  dataScopeName: string
  multiSelect: boolean
  dispatchRoleStateUpdate: any
}

export const TextDataScope: React.FC<Props> = ({
  isRequired,
  errorMessage,
  dataScopeName,
  multiSelect,
  dispatchRoleStateUpdate
}) => {
  const {t} = useTranslation()
  const fieldName = `commonDataScopes.${dataScopeName}`

  return (
    <Field
      key={dataScopeName}
      name={fieldName}
      validate={checkRequired(
        isRequired,
        multiSelect,
        t('roleAssignment.dataScopeRequired', {
          dataScope: t(`roleAssignment.dataScope.${dataScopeName}`)
        })
      )}
    >
      {({field, form}: any) => (
        <TextField
          label={t(`roleAssignment.dataScope.${dataScopeName}`)}
          {...field}
          required={isRequired}
          margin="normal"
          fullWidth
          error={!!errorMessage}
          helperText={errorMessage}
          onChange={(event) => {
            field.onChange(event)

            dispatchRoleStateUpdate({
              type: CHANGE_COMMON_DATASCOPE_VALUE,
              payload: {
                name: dataScopeName,
                value: event.target.value
              }
            })
          }}
          value={form.values.commonDataScopes[dataScopeName] || ''}
        />
      )}
    </Field>
  )
}
