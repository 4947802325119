import {Typography} from '@hconnect/uikit'
import React from 'react'

import {useCustomer} from '../../hooks/useCustomer'

import {useStyles} from './CustomersScopesModal.styles'

type CustomerSideBarProps = {
  customerId: string
  isSelected: boolean
  setSelectedCustomerId: (customerId: string) => void
}
export const CustomerSideBar = ({
  customerId,
  isSelected,
  setSelectedCustomerId
}: CustomerSideBarProps) => {
  const classes = useStyles()
  const customerQueryInfo = useCustomer({customerId})
  const customer = customerQueryInfo.data

  return (
    <div
      key={customerId}
      className={isSelected ? classes.selectedCustomer : classes.customer}
      onClick={() => {
        setSelectedCustomerId(customerId)
      }}
    >
      <Typography variant="body2">{'#' + customer?.customerNumber}</Typography>
      <Typography variant="h4">{customer?.customerName}</Typography>
    </div>
  )
}
