import {Box, CardContent, Skeleton} from '@mui/material'
import React from 'react'

import {RoleCard} from '../../../components/Role/RoleCard'

export const RolesSkeleton = () => {
  return (
    <RoleCard>
      <CardContent>
        <Box>
          <Box style={{display: 'flex', justifyContent: 'space-between'}}>
            <Skeleton width={150} />
            <Skeleton width={80} />
          </Box>
          <Skeleton width={120} style={{marginBottom: '15px'}} />
          <Skeleton width={150} />
          <Skeleton width={120} style={{marginBottom: '15px'}} />
          <Skeleton width={150} />
          <Skeleton width={120} style={{marginBottom: '15px'}} />
        </Box>
      </CardContent>
      <Box style={{display: 'flex', justifyContent: 'space-between', padding: '0 16px'}}>
        <Skeleton width={150} height={60} />
        <Skeleton width={150} height={60} />
      </Box>
    </RoleCard>
  )
}
