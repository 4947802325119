import {Customer} from '@hconnect/apiclient'
import {startCase, toLower, trim, replace} from 'lodash'

import {RoleType} from '../../modules/ManageUsers.selectors'

export const removeEmptyArraysFromObject = (
  data: Record<string, unknown>
): Record<string, unknown> => {
  // removes keys with [], null,  as value
  // EXAMPLE INPUT: {asdf: []} OUTPUT: {}

  const retval: Record<string, any> = {}

  for (const [key, value] of Object.entries(data)) {
    if (!Array.isArray(value) || value.length > 0) {
      retval[key] = value
    }
  }
  return retval
}

export const prepareAssignRolesPayload = (data: Record<string, any>): Record<string, unknown> =>
  // remove empty arrays, e.g. customerIds, from dataScope
  // {
  //   "dataScope": {
  //     "countryId": "DE",
  //     "orgUnitId": "0093",
  //     "businessLine": "RMC",
  //     "customerIds": []
  //   },
  // }
  // see https://jira.grouphc.net/browse/INC-127

  ({...data, dataScope: removeEmptyArraysFromObject(data.dataScope as Record<string, unknown>)})

export const createEmptyCustomer = (): Customer => ({
  customerId: 'unknown',
  customerName: 'unknown'
})

export const checkRequired =
  (isRequired: boolean, multiSelect: boolean, errorMessage: string) =>
  (value: unknown): string | undefined => {
    if (isRequired && (multiSelect ? !Array.isArray(value) || value.length === 0 : !value)) {
      return errorMessage
    }

    return undefined
  }

export const isProjectsCountry = (countryId: string): boolean => ['AU', 'HK'].includes(countryId)

export const prettyRoleName = (roleType: RoleType): string => {
  const roleTypeWithSpaces = toLower(trim(replace(roleType, /_/g, ' ')))
  return startCase(roleTypeWithSpaces)
}

export const payerActivatedRoles = [
  'ACCOUNTANT',
  'BUSINESS_OWNER',
  'CONTROLLER',
  'SALES_AGENT',
  'ACCOUNTANT_NOTESTS'
]
