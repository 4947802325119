import {Typography} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core/styles'
import {FormikState} from 'formik'
import React from 'react'

import {GroupedRoleAssignment} from '../../modules/ManageUsers.selectors'

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error.dark
  },
  errorTitle: {
    color: theme.palette.error.dark,
    fontWeight: 'bold'
  }
}))

interface Props {
  form: FormikState<GroupedRoleAssignment>
}

export const FormErrors: React.FC<Props> = ({form}) => {
  const classes = useStyles()
  const {status} = form

  if (status.type !== 'error') {
    return null
  }

  if (status.error.response && status.error.response.data) {
    return (
      <>
        <Typography className={classes.errorTitle}>{status.error.response.data.title}</Typography>
        <Typography className={classes.errorMessage}>
          {status.error.response.data.message}
        </Typography>
      </>
    )
  }

  return <Typography className={classes.errorMessage}>{status.error.toString()}</Typography>
}
