import {Customer} from '@hconnect/apiclient'
import {Typography} from '@hconnect/uikit'
import {
  Box,
  ButtonBase,
  InputAdornment,
  LinearProgress,
  TextField,
  TextFieldProps
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import {CircularProgress} from '@mui/material'
import {AxiosError} from 'axios'
import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useAdminPilotUser} from '../common/hooks/useAdminPilotUser'
import {RoleCustomerLookupChip} from '../components/Role/RoleCustomerLookupChip'
import {useCustomerByNumber, useCustomerWithoutNumber} from '../hooks/useCustomer'
import {GroupedRoleAssignment} from '../modules/ManageUsers.selectors'

import {CustomerOrPayerDropdown} from './Roles/CustomerOrPayerDropdown/CustomerOrPayerDropdown'

const useStyles = makeStyles((theme) => ({
  chipsContainer: {
    margin: `0 -${theme.spacing(0.25)}px`
  },
  chip: {
    margin: `0 ${theme.spacing(0.25)}px ${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  noValueLabel: {
    fontStyle: 'italic',
    textAlign: 'center'
  }
}))

interface Props {
  customers: Customer[]
  groupedRoleAssignment: GroupedRoleAssignment
  onChangeCustomerIds: (customerIds: string[]) => void
  dataScopeName: string
  showCustomerOrPayerDropdown?: boolean
  setShowCustomerOrPayerDropdown: (show: boolean) => void
  customerAdminData?: Record<string, string | undefined>
}

export const RoleCustomerLookupTextField: FunctionComponent<
  Props & Omit<TextFieldProps, 'onChange'>
  // eslint-disable-next-line complexity
> = ({
  onChangeCustomerIds,
  customers,
  helperText,
  groupedRoleAssignment,
  dataScopeName,
  customerAdminData,
  setShowCustomerOrPayerDropdown,
  ...props
}) => {
  const classes = useStyles()

  const {
    variantDataScopes: {payerIds}
  } = groupedRoleAssignment
  const {t} = useTranslation()

  const [textFieldValue, setTextFieldValue] = useState<string>('')
  // const [noResult, setNoResult] = useState<string>('')
  const {countryId, orgUnitId, businessLine} = groupedRoleAssignment.commonDataScopes
  const isUserAdminPilot = useAdminPilotUser()

  const customerAdminQueryInfo = useCustomerWithoutNumber(
    customerAdminData?.countryId || '',
    customerAdminData?.orgUnitId || '',
    customerAdminData?.businessLine || '',
    !!customerAdminData && groupedRoleAssignment.variantDataScopes.customersScopes.length === 0
  )

  const customerQueryInfo = useCustomerByNumber(
    textFieldValue,
    countryId as string,
    orgUnitId as string,
    businessLine as string,
    false
  )

  const newCustomers = customerQueryInfo.data
  const newCustomerAdminCustomers = customerAdminQueryInfo.data
  const errorMessage = (
    (customerAdminQueryInfo.error as AxiosError) || (customerQueryInfo.error as AxiosError)
  )?.message

  const noResultError =
    newCustomers?.length === 0 ? t(`roleAssignment.noLookupResult.${dataScopeName}`) : ''

  const handleLookup = async () => customerQueryInfo.refetch()

  useEffect(() => {
    if (!isUserAdminPilot && newCustomers && newCustomers.length > 0) {
      onChangeCustomerIds(newCustomers.map((customer) => customer.customerId))
      customerQueryInfo.remove()
    }

    newCustomerAdminCustomers &&
      setShowCustomerOrPayerDropdown(newCustomerAdminCustomers?.length > 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCustomers, newCustomerAdminCustomers])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return
    }

    event.preventDefault()
    void handleLookup()
  }

  const handleAddCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
    newCustomerAdminCustomers &&
      newCustomerAdminCustomers.length > 0 &&
      onChangeCustomerIds(
        newCustomerAdminCustomers
          .filter((customer) => customer.customerId === event.target.value)
          .map((value) => value.customerId)
      )
    setShowCustomerOrPayerDropdown(false)
  }

  // don't allow to enter customers if payers exist
  const disabled = (payerIds && payerIds.length > 0) || props.disabled
  const hidden =
    !customerAdminQueryInfo.isFetching &&
    isUserAdminPilot &&
    (groupedRoleAssignment.variantDataScopes.customersScopes.length > 0 ||
      !newCustomerAdminCustomers)

  if (customerAdminQueryInfo.isFetching) {
    return <CircularProgress style={{margin: '8px 0'}} />
  }

  if (hidden) {
    return null
  }

  return (
    <>
      {!isUserAdminPilot ? (
        <TextField
          {...props}
          label={t(`roleAssignment.dataScope.${dataScopeName}`)}
          variant="standard"
          value={disabled ? '' : textFieldValue}
          onChange={(event) => setTextFieldValue(event.target.value)}
          onKeyDown={handleKeyDown}
          error={!!helperText || customerQueryInfo.isError || !!noResultError}
          helperText={errorMessage || helperText || noResultError}
          type="search"
          disabled={disabled}
          data-test-id="customer-number-lookup"
          InputProps={{
            readOnly: customerQueryInfo.isInitialLoading,
            disabled,
            endAdornment:
              textFieldValue.length > 0 && !disabled ? (
                <InputAdornment position="end" variant="outlined">
                  <ButtonBase
                    onClick={() => {
                      void handleLookup()
                    }}
                    data-test-id="button-customer-lookup"
                  >
                    <AddIcon />
                  </ButtonBase>
                </InputAdornment>
              ) : undefined
          }}
        />
      ) : (
        <CustomerOrPayerDropdown
          dataScope="customerIds"
          name="customerSelect"
          onChange={handleAddCustomer}
          disabled={disabled}
          options={
            customerAdminQueryInfo.data?.map((data) => ({
              label: data.customerNumber || '',
              subLabel: data.customerName || '',
              value: data.customerId || ''
            })) || []
          }
        />
      )}
      {(customerAdminQueryInfo.isInitialLoading || customerQueryInfo.isInitialLoading) && (
        <Box mb={1}>
          <LinearProgress variant="indeterminate" />
        </Box>
      )}
      {(!customerQueryInfo.isInitialLoading || !customerAdminQueryInfo.isInitialLoading) &&
        !customerAdminQueryInfo.data &&
        customers.length === 0 && (
          <Typography
            className={classes.noValueLabel}
            component="div"
            variant="caption"
            color="secondary"
          >
            {t(`roleAssignment.noValue.${dataScopeName}`)}
          </Typography>
        )}
      {customers.length > 0 && (
        <div className={classes.chipsContainer}>
          {customers.map((customer: Customer) => {
            const {customerId} = customer
            return (
              <RoleCustomerLookupChip
                key={customerId}
                customerId={customerId}
                onDelete={() => {
                  onChangeCustomerIds(
                    customers
                      .map((customer: Customer) => customer.customerId)
                      .filter((i) => i !== customerId)
                  )
                }}
                className={classes.chip}
              />
            )
          })}
        </div>
      )}
    </>
  )
}
