import {TextField} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {GroupedRoleAssignment} from '../../../modules/ManageUsers.selectors'

export const UnsupportedDataScope = ({
  dataScopeName,
  groupedRoleAssignment
}: {
  dataScopeName: string
  groupedRoleAssignment: GroupedRoleAssignment
}) => {
  const {t} = useTranslation()

  const dataScopeValue = groupedRoleAssignment.unsupportedDataScopes[dataScopeName]
  const value = Array.isArray(dataScopeValue) ? dataScopeValue.join('\n') : dataScopeValue

  const translatedDataScopeName = t(`roleAssignment.dataScope.${dataScopeName}`)

  const label =
    translatedDataScopeName === `roleAssignment.dataScope.${dataScopeName}`
      ? dataScopeName
      : translatedDataScopeName

  return (
    <TextField
      key={dataScopeName}
      label={label}
      value={value || '-'}
      InputProps={{readOnly: true, disableUnderline: true}}
      InputLabelProps={{shrink: true}}
      margin="normal"
      fullWidth
      multiline
    />
  )
}
