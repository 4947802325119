import {useEffect, useState} from 'react'

import {DropdownOption} from '../../../common/DropdownOption'
import {useCustomerWithoutNumber} from '../../../hooks/useCustomer'
import {usePayerWithoutNumber} from '../../../hooks/usePayer'
import {RoleOptions} from '../RoleEditLayout'

export const useCustomerData = (
  selectedRoleOptions: RoleOptions,
  isUserAdminPilot: boolean,
  countryIdOptions?: DropdownOption[],
  businessLineOptions?: DropdownOption[],
  orgUnitIdOptions?: DropdownOption[]
) => {
  const [customerAdminData, setCustomerAdminData] = useState<Record<string, string | undefined>>()

  // eslint-disable-next-line complexity
  useEffect(() => {
    ;(selectedRoleOptions.businessLine.isSingle || selectedRoleOptions.businessLine.value) &&
      (selectedRoleOptions.countryId.isSingle || selectedRoleOptions.countryId.value) &&
      (selectedRoleOptions.orgUnitId.isSingle || selectedRoleOptions.orgUnitId.value) &&
      countryIdOptions &&
      businessLineOptions &&
      businessLineOptions.length > 0 &&
      orgUnitIdOptions &&
      orgUnitIdOptions.length > 0 &&
      isUserAdminPilot &&
      setCustomerAdminData({
        countryId: selectedRoleOptions.countryId.isSingle
          ? countryIdOptions?.[0].key
          : selectedRoleOptions.countryId.value,
        businessLine: selectedRoleOptions.businessLine.isSingle
          ? businessLineOptions?.[0].key
          : selectedRoleOptions.businessLine.value,
        orgUnitId: selectedRoleOptions.orgUnitId.isSingle
          ? orgUnitIdOptions?.[0].key
          : selectedRoleOptions.orgUnitId.value
      })
  }, [countryIdOptions, businessLineOptions, orgUnitIdOptions, selectedRoleOptions])

  const customerAdminQueryInfo = useCustomerWithoutNumber(
    customerAdminData?.countryId || '',
    customerAdminData?.orgUnitId || '',
    customerAdminData?.businessLine || '',
    !!customerAdminData
  )

  const payerAdminQueryInfo = usePayerWithoutNumber(
    customerAdminData?.countryId || '',
    customerAdminData?.orgUnitId || '',
    customerAdminData?.businessLine || '',
    !!customerAdminData
  )

  return {customerAdminQueryInfo, payerAdminQueryInfo, customerAdminData}
}
