import {Customer} from '@hconnect/apiclient'
import {Typography} from '@hconnect/uikit'
import {
  Box,
  ButtonBase,
  InputAdornment,
  LinearProgress,
  MenuItem,
  MenuList,
  Popper,
  TextField,
  TextFieldProps
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import {AxiosError} from 'axios'
import React, {FunctionComponent, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {RoleCustomerLookupChip} from '../components/Role/RoleCustomerLookupChip'
import {useCustomerByName} from '../hooks/useCustomer'
import {GroupedRoleAssignment} from '../modules/ManageUsers.selectors'

const useStyles = makeStyles((theme) => ({
  chipsContainer: {
    margin: `0 -${theme.spacing(0.25)}px`
  },
  chip: {
    margin: `0 ${theme.spacing(0.25)}px ${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  noValueLabel: {
    fontStyle: 'italic',
    textAlign: 'center'
  }
}))

interface Props {
  customers: Customer[]
  groupedRoleAssignment: GroupedRoleAssignment
  onChangeCustomerIds: (customerIds: string[]) => void
  dataScopeName?: string
}

export const RoleCustomerLookupDropdownTextField: FunctionComponent<
  Props & Omit<TextFieldProps, 'onChange'>
> = ({onChangeCustomerIds, customers, helperText, groupedRoleAssignment, ...props}) => {
  const classes = useStyles()

  const {
    variantDataScopes: {payerIds}
  } = groupedRoleAssignment
  const {t} = useTranslation()

  const [open, setOpen] = React.useState(false)
  const [textFieldValue, setTextFieldValue] = useState<string>('')
  const anchorRef = React.useRef<HTMLInputElement | null>(null)

  // const [noResult, setNoResult] = useState<string>('')
  const {countryId, orgUnitId, businessLine} = groupedRoleAssignment.commonDataScopes

  const customerQueryInfo = useCustomerByName(
    textFieldValue,
    countryId as string,
    orgUnitId as string,
    businessLine as string,
    false
  )
  const newCustomers = customerQueryInfo.data
  const errorMessage = (customerQueryInfo.error as AxiosError)?.message
  const noResultError =
    newCustomers?.length === 0 ? t('roleAssignment.noLookupResult.customerName') : ''

  const handleLookup = async () => {
    await customerQueryInfo.refetch()
    setOpen(true)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return
    }

    event.preventDefault()
    void handleLookup()
  }

  const handleClose = (event: Event | React.SyntheticEvent, customer: Customer) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
    onChangeCustomerIds([customer.customerId])
    customerQueryInfo.remove()
    setTextFieldValue('')
  }

  // don't allow to enter customers if payers exist
  const disabled = (payerIds && payerIds.length > 0) || props.disabled
  return (
    <Box>
      <TextField
        {...props}
        label={t('roleAssignment.dataScope.customerName')}
        variant="standard"
        value={disabled ? '' : textFieldValue}
        onChange={(event) => setTextFieldValue(event.target.value)}
        onKeyDown={handleKeyDown}
        error={!!helperText || customerQueryInfo.isError || !!noResultError}
        helperText={errorMessage || helperText || noResultError}
        type="search"
        disabled={disabled}
        ref={anchorRef}
        data-test-id="customer-name-lookup"
        InputProps={{
          readOnly: customerQueryInfo.isLoading,
          disabled,
          endAdornment:
            textFieldValue.length > 0 && !disabled ? (
              <InputAdornment position="end" variant="outlined">
                <ButtonBase
                  onClick={() => {
                    void handleLookup()
                  }}
                  data-test-id="button-customer-lookup"
                >
                  <SearchIcon />
                </ButtonBase>
              </InputAdornment>
            ) : undefined
        }}
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="top"
        style={{
          backgroundColor: 'white',
          boxShadow: '0px 8px 32px 0px rgba(0,0,0,0.12),0px 4px 12px 2px rgba(0,0,0,0.12)',
          maxHeight: '90vh',
          overflowY: 'auto',
          zIndex: 99
        }}
      >
        <MenuList>
          {newCustomers?.map((customer, index) => {
            return (
              <MenuItem
                key={`lookup-dropdown-item-${index}`}
                onClick={(event) => handleClose(event, customer)}
                selected={textFieldValue === customer.customerName}
                data-test-id={`lookup-dropdown-item-${index}`}
                style={{flexDirection: 'column', alignItems: 'flex-start', whiteSpace: 'normal'}}
              >
                <Typography>{customer.customerName}</Typography>
                <Typography>{customer.customerNumber}</Typography>
              </MenuItem>
            )
          })}
        </MenuList>
      </Popper>

      {customerQueryInfo.isLoading && (
        <Box mb={1}>
          <LinearProgress variant="indeterminate" />
        </Box>
      )}
      {!customerQueryInfo.isLoading && customers.length === 0 && (
        <Typography
          className={classes.noValueLabel}
          component="div"
          variant="caption"
          color="secondary"
        >
          {t('roleAssignment.noValue.customerNames')}
        </Typography>
      )}
      {customers.length > 0 && (
        <Box className={classes.chipsContainer}>
          {customers.map((customer: Customer) => {
            const {customerId} = customer
            return (
              <RoleCustomerLookupChip
                key={customerId}
                customerId={customerId}
                onDelete={() => {
                  onChangeCustomerIds(
                    customers
                      .map((customer: Customer) => customer.customerId)
                      .filter((i) => i !== customerId)
                  )
                }}
                className={classes.chip}
              />
            )
          })}
        </Box>
      )}
    </Box>
  )
}
