import {Theme, makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    margin: `0 ${theme.spacing(0.25)}px ${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  deleteButton: {
    minWidth: 'auto'
  },
  editCustomersScopesButton: {
    flexGrow: 0
  },
  customerScopesContainer: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#7F9BA6',
    borderRadius: '3px',
    marginTop: '5px',
    padding: '6px 6px 6px 8px',
    marginBottom: '-1px'
  },
  customersRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  sitesRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px'
  },
  customersFetchingPlaceholderText: {
    marginLeft: 5
  }
}))
