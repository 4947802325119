import {flagPathByCountryCode} from '@hconnect/uikit'
import {CircularProgress, FormControl, InputLabel, TextField} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DropdownOption} from '../../../common/DropdownOption'
import {useDependencyFetcher} from '../../../common/hooks/useDependencyFetcher'
import {DynamicSelectDataScopeInput} from '../../../modules/DataScopeConfiguration'
import {GroupedRoleAssignment} from '../../../modules/ManageUsers.selectors'

const useStyles = makeStyles((theme) => ({
  multiSelect: {
    paddingTop: theme.spacing(2.5)
  },
  dynamicInput: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

interface Props {
  dataScope: string
  groupedRoleAssignment: GroupedRoleAssignment
  configuration: DynamicSelectDataScopeInput
}

export const DynamicLabel: React.FC<Props> = ({
  dataScope,
  groupedRoleAssignment,
  configuration
}) => {
  const {fetchValues} = configuration

  const {t} = useTranslation()
  const classes = useStyles()

  const dependencies =
    configuration.dependsOn !== undefined
      ? configuration.dependsOn
          .map((dep) => groupedRoleAssignment.commonDataScopes[dep])
          .filter((dependency) => dependency !== undefined)
      : []

  const {
    isLoading,
    error,
    data: options
  } = useDependencyFetcher<DropdownOption[]>(fetchValues, dataScope, dependencies.flat())

  if (isLoading) {
    return (
      <FormControl key={dataScope} margin="normal" className={classes.multiSelect} fullWidth>
        <InputLabel shrink variant="standard">
          {t(`roleAssignment.dataScope.${dataScope}`)}
        </InputLabel>
        <CircularProgress size={20} />
      </FormControl>
    )
  }

  if (error) {
    return (
      <FormControl key={dataScope} margin="normal" className={classes.multiSelect} fullWidth>
        <InputLabel shrink variant="standard">
          {t(`roleAssignment.dataScope.${dataScope}`)}
        </InputLabel>
        <div>{groupedRoleAssignment.commonDataScopes[dataScope]}</div>
      </FormControl>
    )
  }

  const textFieldValue = (() => {
    if (!options) {
      return groupedRoleAssignment.commonDataScopes[dataScope]
    }
    const selectedKey = groupedRoleAssignment.commonDataScopes[dataScope]
    const selectedOption = options.find((option) => option.key === selectedKey)

    if (!selectedOption) {
      return groupedRoleAssignment.commonDataScopes[dataScope]
    }

    return selectedOption.label
  })()

  const countriesData = window.intlTelInputGlobals.getCountryData()

  const formattedCountryName = () => {
    if (groupedRoleAssignment.commonDataScopes[dataScope] === 'MOCK') {
      return 'MOCK'
    }

    if (groupedRoleAssignment.commonDataScopes[dataScope] === '*') {
      return 'ALL'
    }
    return countriesData.find(
      (countryData) =>
        countryData.iso2.toUpperCase() === groupedRoleAssignment.commonDataScopes[dataScope]
    )?.name
  }

  return (
    <TextField
      key={dataScope}
      label={t(`roleAssignment.dataScope.${dataScope}`)}
      value={dataScope === 'countryId' ? formattedCountryName() : textFieldValue}
      InputProps={{
        readOnly: true,
        classes: {
          input: classes.dynamicInput
        },
        disableUnderline: true,
        ...(dataScope === 'countryId' &&
          groupedRoleAssignment.commonDataScopes[dataScope] !== 'MOCK' &&
          groupedRoleAssignment.commonDataScopes[dataScope] !== '*' && {
            startAdornment: (
              <img
                src={flagPathByCountryCode(
                  groupedRoleAssignment.commonDataScopes[dataScope] as string
                )}
                alt={`${groupedRoleAssignment.commonDataScopes[dataScope]}-flag`}
                style={{marginRight: '12px'}}
              />
            )
          })
      }}
      InputLabelProps={{shrink: true}}
      margin="normal"
      fullWidth
    />
  )
}
