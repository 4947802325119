import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'

import {RolePayerLookupChip} from '../../components/Role/RolePayerLookupChip'
import {GroupedRoleAssignment} from '../../modules/ManageUsers.selectors'

const useStyles = makeStyles(() => ({
  deleteButton: {
    minWidth: 'auto'
  },
  payersContainer: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#7F9BA6',
    borderRadius: '3px',
    marginTop: '5px',
    padding: '6px 6px 6px 8px',
    marginBottom: '-1px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  }
}))

interface Props {
  groupedRoleAssignment: GroupedRoleAssignment
  isEditing: boolean
  handleDeletePayer?(payerId: string): void
  setShowAnotherCustomerOrPayerDropdown?: (show: boolean) => void
}

export const Payers: React.FC<Props> = ({
  groupedRoleAssignment,
  isEditing,
  handleDeletePayer,
  setShowAnotherCustomerOrPayerDropdown
}) => {
  const classes = useStyles()

  const {payerIds} = groupedRoleAssignment.variantDataScopes

  if (!payerIds || payerIds.length === 0) {
    return null
  }

  return (
    <div className="">
      <div className="">
        {groupedRoleAssignment.variantDataScopes.payerIds?.map((payerId) => (
          <div key={payerId} className={classes.payersContainer}>
            <RolePayerLookupChip key={payerId} payerId={payerId} />
            {isEditing && (
              <Button
                variant="text"
                color="secondary"
                className={classes.deleteButton}
                onClick={() => {
                  handleDeletePayer?.(payerId)
                  setShowAnotherCustomerOrPayerDropdown &&
                    setShowAnotherCustomerOrPayerDropdown(false)
                }}
              >
                <DeleteIcon />
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
