import {FormControl, InputLabel, TextField} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {RoleCustomerLookupChip} from '../../../components/Role/RoleCustomerLookupChip'
import {
  DYNAMIC_SELECT_DATA_SCOPE_TYPE,
  HIDE_DATA_SCOPE_TYPE,
  LOOKUP_DATA_SCOPE_TYPE,
  getDataScopeConfiguration
} from '../../../modules/DataScopeConfiguration'
import {GroupedRoleAssignment} from '../../../modules/ManageUsers.selectors'

import {DynamicLabel} from './DyamicLabel'

const useStyles = makeStyles((theme) => ({
  chipsContainer: {
    margin: `0 -${theme.spacing(0.25)}px`
  },
  chip: {
    margin: `0 ${theme.spacing(0.25)}px ${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  multiSelect: {
    paddingTop: theme.spacing(2.5)
  }
}))

interface Props {
  dataScopeName: string
  groupedRoleAssignment: GroupedRoleAssignment
}

export const DataScope: React.FC<Props> = ({dataScopeName, groupedRoleAssignment}) => {
  const classes = useStyles()
  const configuration = getDataScopeConfiguration(dataScopeName)
  const {t} = useTranslation()
  switch (configuration.type) {
    case HIDE_DATA_SCOPE_TYPE:
      return null

    case LOOKUP_DATA_SCOPE_TYPE:
      return (
        <FormControl key={dataScopeName} margin="normal" className={classes.multiSelect} fullWidth>
          <InputLabel shrink variant="standard">
            {t(`roleAssignment.dataScope.${dataScopeName}`)}
          </InputLabel>
          <div className={classes.chipsContainer}>
            {dataScopeName === 'customerIds' &&
              /* @ts-expect-error TODO */
              (groupedRoleAssignment.dataScopes[dataScopeName] || []).map((customerId) => (
                <RoleCustomerLookupChip
                  key={customerId}
                  customerId={customerId}
                  className={classes.chip}
                />
              ))}
          </div>
        </FormControl>
      )

    case DYNAMIC_SELECT_DATA_SCOPE_TYPE:
      return (
        <DynamicLabel
          key={dataScopeName}
          dataScope={dataScopeName}
          groupedRoleAssignment={groupedRoleAssignment}
          configuration={configuration}
        />
      )

    default:
      return (
        <TextField
          key={dataScopeName}
          label={t(`roleAssignment.dataScope.${dataScopeName}`)}
          value={groupedRoleAssignment.commonDataScopes[dataScopeName] || '-'}
          InputProps={{readOnly: true, disableUnderline: true}}
          InputLabelProps={{shrink: true}}
          margin="normal"
          fullWidth
        />
      )
  }
}
