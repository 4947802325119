import {Theme, makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    overflow: 'hidden',
    position: 'absolute',
    width: '80%',
    height: '80vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    display: 'flex',
    flexDirection: 'row',
    outline: 'none',
    borderRadius: theme.shape.borderRadius
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  customerSelector: {
    width: '300px',
    backgroundColor: theme.palette.grey[300],
    flexGrow: 0,
    flexShrink: 0
  },
  selectedCustomer: {
    backgroundColor: '#ffffff',
    padding: `${theme.spacing(2)}px`,
    position: 'relative',
    '&::before': {
      content: '""',
      boxShadow: theme.shadows[5],
      zIndex: 0,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    }
  },
  customer: {
    padding: `${theme.spacing(2)}px`
  },
  sites: {
    backgroundColor: '#ffffff',
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  sitesSelectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px`
  },
  sitesSelectionHeaderSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  customersText: {},
  allSitesSelectedPlaceholderInfoText: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center'
  },
  projectInfoContainer: {
    display: 'flex'
  },
  projectInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  projectRow: {
    display: 'flex',
    flexDirection: 'column',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[100]
  },
  checkbox: {
    display: 'flex',
    alignSelf: 'center',
    justifySelf: 'center',
    margin: theme.spacing(1)
  },
  projectItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  projectItemLeft: {
    display: 'flex'
  },
  projectRowSiteItem: {
    marginLeft: '15px'
  },
  siteRowNoBorders: {
    display: 'flex',
    alignItems: 'center'
  },
  siteRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[100]
  },
  siteLocationIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5)
  },
  allSitesSelectedPlaceholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  sitesDisclaimer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.light,
    padding: '10px 20px'
  },
  sitesContainer: {
    overflowY: 'auto',
    borderWidth: '10px'
  },
  switchStyle: {
    marginLeft: 0
  }
}))
