import {getPayerByNumber, Payer} from '@hconnect/apiclient'
import {Typography} from '@hconnect/uikit'
import {
  Box,
  ButtonBase,
  InputAdornment,
  LinearProgress,
  TextField,
  TextFieldProps
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import {CircularProgress} from '@mui/material'
import React, {FunctionComponent, Reducer, useEffect, useReducer} from 'react'
import {useTranslation} from 'react-i18next'

import {api} from '../App.store'
import {useAdminPilotUser} from '../common/hooks/useAdminPilotUser'
import {usePayerWithoutNumber} from '../hooks/usePayer'
import {GroupedRoleAssignment} from '../modules/ManageUsers.selectors'

import {CustomerOrPayerDropdown} from './Roles/CustomerOrPayerDropdown/CustomerOrPayerDropdown'
import {
  initialTextFieldState,
  LookupState,
  TextFieldAction,
  textFieldReducer
} from './TextFieldReducer'

const useStyles = makeStyles((theme) => ({
  chipsContainer: {
    margin: `0 -${theme.spacing(0.25)}px`
  },
  chip: {
    margin: `0 ${theme.spacing(0.25)}px ${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  noValueLabel: {
    fontStyle: 'italic',
    textAlign: 'center'
  }
}))

interface Props {
  payers: Payer[]
  groupedRoleAssignment: GroupedRoleAssignment
  onChangePayerIds: (payerIds: string[]) => void
  dataScopeName: string
  setShowCustomerOrPayerDropdown: (show: boolean) => void
  customerAdminData?: Record<string, string | undefined>
}

export const RolePayerLookupTextField: FunctionComponent<
  Props & Omit<TextFieldProps, 'onChange'>
  // eslint-disable-next-line complexity
> = ({
  onChangePayerIds,
  error,
  payers,
  helperText,
  groupedRoleAssignment,
  dataScopeName,
  customerAdminData,
  setShowCustomerOrPayerDropdown,
  ...props
}) => {
  const classes = useStyles()
  const [state, dispatch] = useReducer<Reducer<LookupState, TextFieldAction>>(
    textFieldReducer,
    initialTextFieldState
  )
  const isUserAdminPilot = useAdminPilotUser()

  const {
    variantDataScopes: {customersScopes}
  } = groupedRoleAssignment
  const {t} = useTranslation()

  const payerAdminQueryInfo = usePayerWithoutNumber(
    customerAdminData?.countryId || '',
    customerAdminData?.orgUnitId || '',
    customerAdminData?.businessLine || '',
    !!customerAdminData
  )

  useEffect(() => {
    payerAdminQueryInfo.data && setShowCustomerOrPayerDropdown(payerAdminQueryInfo.data.length > 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payerAdminQueryInfo.data])

  const handleLookup = async () => {
    const {text} = state
    if (text.trim().length === 0) {
      return
    }

    try {
      dispatch({type: 'LOOKUP_REQUEST'})
      const {countryId, orgUnitId, businessLine} = groupedRoleAssignment.commonDataScopes

      const countryIdArgument = typeof countryId === 'string' ? countryId : ''
      const orgUnitIdArgument = typeof orgUnitId === 'string' ? orgUnitId : ''
      const businessLineArgument = typeof businessLine === 'string' ? businessLine : ''

      const payers = await getPayerByNumber(api)(text, {
        countryId: countryIdArgument,
        orgUnitId: orgUnitIdArgument,
        businessLine: businessLineArgument
      })

      if (payers.length > 0) {
        dispatch({type: 'LOOKUP_SUCCESS'})

        const payerIds: string[] = payers.map((c: Payer) => c.payerId)

        onChangePayerIds(payerIds)
      } else {
        dispatch({
          type: 'LOOKUP_FAILURE',
          payload: new Error(t(`roleAssignment.noLookupResult.${dataScopeName}`)),
          error: true
        })
      }
    } catch (err) {
      dispatch({type: 'LOOKUP_FAILURE', payload: err as Error, error: true})
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== 'Enter') {
      return
    }

    event.preventDefault()
    void handleLookup()
  }

  const handleAddPayer = (event: React.ChangeEvent<HTMLInputElement>) => {
    payerAdminQueryInfo.data &&
      payerAdminQueryInfo.data.length > 0 &&
      onChangePayerIds(
        payerAdminQueryInfo.data
          .filter((payer) => payer.payerId === event.target.value)
          .map((value) => value.payerId)
      )
    setShowCustomerOrPayerDropdown(false)
  }

  // don't allow to enter payers if customers exist
  const disabled = customersScopes.length > 0 || props.disabled
  const hidden =
    !payerAdminQueryInfo.isFetching &&
    isUserAdminPilot &&
    (!payerAdminQueryInfo.data || payerAdminQueryInfo.data.length === 0)

  if (payerAdminQueryInfo.isFetching) {
    return <CircularProgress style={{margin: '8px 0'}} />
  }

  if (hidden) {
    return null
  }

  return (
    <>
      {!isUserAdminPilot ? (
        <TextField
          {...props}
          label={t(`roleAssignment.dataScope.${dataScopeName}`)}
          variant="standard"
          value={disabled ? '' : state.text}
          onChange={(event) => dispatch({type: 'CHANGE_TEXT', payload: event.target.value})}
          onKeyDown={handleKeyDown}
          error={error || state.errorMessage !== null}
          helperText={state.errorMessage || helperText}
          type="search"
          disabled={disabled}
          InputProps={{
            readOnly: state.isLoading,
            disabled,
            endAdornment:
              state.text.length > 0 && !disabled ? (
                <InputAdornment position="end" variant="outlined">
                  <ButtonBase onClick={handleLookup} data-test-id="button-payer-lookup">
                    <AddIcon />
                  </ButtonBase>
                </InputAdornment>
              ) : undefined
          }}
        />
      ) : (
        <CustomerOrPayerDropdown
          dataScope="payerIds"
          name="payerSelect"
          onChange={handleAddPayer}
          disabled={disabled}
          options={
            payerAdminQueryInfo.data?.map((data) => ({
              label: data.payerNumber || '',
              subLabel: data.payerName || '',
              value: data.payerId || ''
            })) || []
          }
        />
      )}

      {state.isLoading && (
        <Box mb={1}>
          <LinearProgress variant="indeterminate" />
        </Box>
      )}
      {!isUserAdminPilot && !state.isLoading && payers.length === 0 && (
        <Typography
          className={classes.noValueLabel}
          component="div"
          variant="caption"
          color="secondary"
        >
          {t(`roleAssignment.noValue.${dataScopeName}`)}
        </Typography>
      )}
    </>
  )
}
